import { createTemplate, updateTemplate } from "@/actions/config/templates"
import { LoadingButton } from "@/components/buttons/loading-button"
import { VariablesMenu } from "@/components/company/shortcuts/VariablesMenu"
import { CardFile } from "@/components/crm/EmailInput/CardFile"
import { getFileType } from "@/components/crm/uploadMultimedia/utils/sendMultimediaWithPersonalWhatsApp"
import { Badge } from "@/components/ui/badge"
import { Button } from "@/components/ui/button"
import { CustomFormField } from "@/components/ui/custom-form-field"
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog"
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"
import { IFile } from "@/helpers/getFileWithBase64"
import { uploadFileToS3ByObject } from "@/helpers/uploadFileToS3"
import useUploadFile from "@/hooks/useUploadFile"
import { zodResolver } from "@hookform/resolvers/zod"
import { useMutation } from "@tanstack/react-query"
import { Editor } from "@tinymce/tinymce-react"
import { Files, FileUp } from "lucide-react"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { z } from "zod"

interface ModalAddTemplatesProps {
    open: boolean | string | object,
    refetchTemplates: () => void,
    onClose: () => void,
    updateData?: Record<string, any>,
}

const templateSchema = z.object({
    title: z.string().min(1, { message: 'El campo titulo es requerido' }),
    message: z.string().min(1, { message: 'El campo mensaje es requerido' }),
    subject: z.string().min(1, { message: 'El campo asunto es requerido' }).optional(),
    files: z.array(z.object({
        path: z.string().optional(),
        type: z.string().optional(),
        name: z.string().optional(),
        size: z.number().optional(),
        isUpload: z.boolean().optional()
    }).optional()).optional()
})

type TemplateFormValues = z.infer<typeof templateSchema>

const resetValues = {
    title: '',
    message: '',
    subject: undefined,
    files: []
}

export const ModalAddTemplates = ({ open, onClose, refetchTemplates, updateData }: ModalAddTemplatesProps) => {
    const [loading, setLoading] = useState(false)

    const contextForm = useForm<TemplateFormValues>({
        resolver: zodResolver(templateSchema),
        defaultValues: {
            title: '',
            message: '',
            subject: undefined,
        }
    });

    const isUpdateMode = !!updateData;

    useEffect(() => {
        if (updateData) {
            contextForm.reset({
                ...updateData,
                files: (updateData.files || []).map(file => formatFile(file, 'normal'))
            })
        }
    }, [updateData]);

    const { mutate, isPending } = useMutation({
        mutationFn: ({ type, body, id }: { type: 'update' | 'create', body: any, id?: string }) => {
            if (type === 'create') return createTemplate(body)
            return updateTemplate(body, id)
        },
        onSuccess: () => {
            onClose()
            refetchTemplates()
            contextForm.reset(resetValues)
        },
        onSettled: () => setLoading(false)
    })

    const onSubmit = async (values: TemplateFormValues) => {
        setLoading(true)

        const data = { ...values, channel: open }
        let files: any[] = (values.files || [])

        if (files.length) {
            // La función maneja los casos que la url ya este subida a un servidor.
            files = await uploadFileToS3ByObject(files)
        }

        // Convertimos los archivos al formato que el servidor espera.
        files = files.map((file) => formatFile(file, 'upload-server'))
        data['files'] = files

        mutate({
            body: data,
            id: updateData?._id || '',
            type: isUpdateMode ? 'update' : 'create'
        })
    }

    const handleClose = () => {
        onClose();
        contextForm.reset(resetValues);
    };

    return (
        <Dialog open={!!open} onOpenChange={handleClose}>
            <DialogContent className={`${open == 'email' ? 'min-w-[80%]' : null}`}>
                <DialogHeader>
                    <div className="flex items-center gap-2">
                        <Files size={16} strokeWidth={2.5} className='text-[#4B5563]' />
                        <DialogTitle className="text-[#4B5563]">
                            {
                                isUpdateMode
                                    ? 'Actualizar plantilla'
                                    : 'Nueva plantilla:'
                            }
                        </DialogTitle>
                        <BadgeConfig title={open} />
                    </div>
                </DialogHeader>
                <Form {...contextForm}>
                    <form onSubmit={contextForm.handleSubmit(onSubmit)} id="formCreateTemplate" className="space-y-2">
                        <FormField
                            control={contextForm.control}
                            name={'title'}
                            render={({ field }) => (
                                <FormItem className="w-full">
                                    <FormLabel>Título</FormLabel>
                                    <FormControl className="p-0">
                                        <Input className="bg-[#F1F3F4] px-2" placeholder="Escribe el título de la plantilla" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        {open == 'email' && (
                            <>
                                <FormField
                                    control={contextForm.control}
                                    name={'subject'}
                                    render={({ field }) => (
                                        <FormItem className="w-full">
                                            <FormLabel>Asunto</FormLabel>
                                            <FormControl className="p-0">
                                                <Input className="bg-[#F1F3F4] px-2" placeholder="Escribe un asunto" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={contextForm.control}
                                    name={'message'}
                                    render={({ field }) => (
                                        <FormItem className="w-full">
                                            <div className="flex flex-row items-center justify-between w-full">
                                                <FormLabel>Mensaje</FormLabel>
                                                <VariablesMenu
                                                    addValue={(newValue) => {
                                                        field.onChange(`${field.value || ''} {{${newValue}}}`)
                                                    }}
                                                    classNameTrigger='border'
                                                />
                                            </div>
                                            <FormControl className="p-0">
                                                <Editor
                                                    initialValue={field.value}
                                                    apiKey="jemqu9ooy39qqvdocbyf52lfexkir04ihmp01pkzdmim583f"
                                                    init={{
                                                        language: "es",
                                                        height: 430,
                                                        style_formats_autohide: true,
                                                        style_formats_merge: true,
                                                        menubar: false,
                                                        plugins: 'anchor autolink codesample emoticons image link lists table',
                                                        toolbar: 'blocks fontfamily fontsize | bold italic underline strikethrough | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons',
                                                        tinycomments_mode: 'embedded',
                                                        branding: false,
                                                        welcome: false,
                                                        statusbar: false,
                                                        draggable_modal: true,
                                                        inline_styles: true,
                                                    }}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.getContent())
                                                    }}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                            </>
                        )}
                        {open == 'message' && (
                            <FormField
                                control={contextForm.control}
                                name={'message'}
                                render={({ field }) => (
                                    <FormItem className="w-full">
                                        <div className="flex flex-row items-center justify-between w-full">
                                            <FormLabel>Mensaje</FormLabel>
                                            <VariablesMenu
                                                addValue={(newValue) => {
                                                    field.onChange(`${field.value || ''} {{${newValue}}}`)
                                                }}
                                                classNameTrigger='border'
                                            />
                                        </div>
                                        <FormControl className="p-0">
                                            <Textarea className="bg-[#F1F3F4] p-2" placeholder="Escribe un mensaje..." {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        )}
                        <CustomFormField
                            control={contextForm.control}
                            name="files"
                            fnElement={({ field }) => (
                                <UploadFile
                                    value={field.value}
                                    templateType={open as UploadFileProps['templateType']}
                                    onChangeValue={field.onChange}
                                />
                            )}
                        />
                        <div className="flex w-full justify-between mt-2">
                            <Button variant='link' type='button' className='font-normal p-0' onClick={handleClose}>Cancelar</Button>
                            <LoadingButton
                                loading={isPending || loading}
                                variant='link'
                                type='submit'
                                className='font-normal p-0'
                            >
                                {
                                    isUpdateMode
                                        ? 'Actualizar plantilla'
                                        : 'Guardar plantilla'
                                }
                            </LoadingButton>
                        </div>
                    </form>
                </Form>
            </DialogContent>
        </Dialog>

    )
}

export const BadgeConfig = ({ title }) => {
    return (
        <Badge className={`capitalize rounded-md gap-1 ${title == 'email' ? 'bg-[#FFBA00] hover:bg-[#FFBA00] text-white' : title == 'message' ? 'bg-[#DBEAFE] hover:bg-[#DBEAFE] text-[#1F2937]' : null}`}>
            {Icon(title)}
            {title == 'message' ? 'Mensaje' : title}
        </Badge>
    )
}

const Icon = (title) => {
    switch (title) {
        case 'email':
            return <Files size={14} className='text-white' />
        case 'message':
            return <Files size={14} className='text-[#1F2937]' />
        default:
            return <Files size={14} className='text-white' />
    }
}

interface UploadFileProps {
    value: TemplateFormValues['files'],
    onChangeValue: (files: TemplateFormValues['files']) => void,
    templateType: 'email' | 'message'
}

export const formatFile = (file, format: 'normal' | 'upload-server' = 'upload-server') => {
    if (format == 'upload-server') {
        const { name, path, size, type } = file
        return {
            url: path,
            mimetype: type || 'application/octet-stream',
            fileType: getFileType(type),
            fileLength: size,
            fileName: name
        }
    }

    const { url, mimetype, fileLength, fileName, fileType } = file
    return {
        name: fileName,
        size: fileLength,
        type: mimetype,
        mediaType: fileType || getFileType(mimetype || ''),
        path: url
    }
}

export function UploadFile({
    onChangeValue,
    value = [],
    templateType
}: UploadFileProps) {
    const isEmailType = templateType == 'email'

    const { getInputProps, open, result = [] } = useUploadFile({
        maxFiles: 1,
        multiple: isEmailType
    })

    useEffect(() => {
        if (!result || (result && result.length === 0)) return;
        let newValue = result.map(file => ({ ...file, isUpload: true }))
        if (isEmailType) newValue = [...value, ...newValue]
        onChangeValue(newValue)
    }, [result])

    const onHandleDeleteFile = (inx: number) => {
        const allValues = [...value]
        allValues.splice(inx, 1)
        onChangeValue(allValues)
    }

    return (
        <div className="grid gap-2">
            <Button className="" variant={'outline'} type="button" onClick={open}>
                <FileUp size={17} className="mr-2" />
                Subir archivo
            </Button>
            <input type="file" hidden {...getInputProps()} />
            <ul className="grid gap-2">
                {
                    value.map((file, inx) => {
                        return (
                            <CardFile
                                key={`file-${inx}`}
                                file={file as IFile}
                                handleDeleteFile={() => onHandleDeleteFile(inx)}
                            />
                        )
                    })
                }
            </ul>
        </div>
    )
}