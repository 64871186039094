import BadgePill from "./BadgePill";

interface BotStatusProps {
    isActive?: boolean
}

const BotStatusPill = ({ isActive }: BotStatusProps) => {

    if (!isActive) return null;

    return (
        <BadgePill>
            <div className="flex flex-row gap-1 items-center">
                <span className="rounded-full bg-green-500 h-2.5 w-2.5" />
                <p>Bot Activo</p>
            </div>
        </BadgePill>
    )
}

export default BotStatusPill