import { isAuth } from "@/actions/auth";
import { Button } from "@/components/ui/button";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { Separator } from "@/components/ui/separator";
import SimpleTooltip from "@/components/ui/simple-tooltip";
import { Textarea } from "@/components/ui/textarea";
import { ToolTipAndAction } from "@/components/ui/tooltip-and-action";
import { useToast } from "@/components/ui/use-toast";
import { addStrToInput } from "@/helpers";
import { useToggle } from "@/hooks/useToggle";
import useUploadFile from "@/hooks/useUploadFile";
import { UPLOAD_TYPE } from "@/interfaces/Interface";
import { setScheduleMessageDate, setSearchTemplate, setStaticTexts, setTemplateMode, setUploadMultimediaType } from "@/redux/slices/crm";
import { StickyNote2 } from "@mui/icons-material";
import axios from "axios";
import { CalendarClock, File as IFile, Image as ImageICon, Laugh, Paperclip, Send, X } from "lucide-react";
import mixpanel from "mixpanel-browser";
import moment from "moment";
import { MouseEvent, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cn } from '../../../lib/utils';
import BadgePill from "../Pills/BadgePill";
import { defaultValueForEditor } from '../uploadMultimedia/constants/index';
import AudioRecorder from "./AudioRecorder";
import { useChatInputContext } from "./Context";
import EmojiMenu from "./EmojiMenu";
import RecordAudioModal from "./RecordAudioModal";
import { ReplyCard } from "./ReplyCard";
import ScheduledModal from "./ScheduledModal";
import TemplateSelect from './TemplateSelect';
import { ChatInputProps, PropsButtonFile } from "./TemplateSelect/interfaces";

moment.locale('es');

const defaultColorSchemeConfig = {
    background: "inherit",
    borders: "inherti",
    bordersFocus: "inherti",
    submitButton: "inherti",
    backButton: "inherti",
    emojiButton: "inherti",
}

const multimediaFeatureDefaultConfig = {
    image: false,
    file: false,
    voice: false,
}

const imagesAndVideoMimeType = ['image/png', 'image/jpg', 'image/jpeg', 'video/mp4']

const detailInput = [
    {
        iconOrKey: 'Enter',
        label: 'Enviar'
    },
    {
        iconOrKey: 'Shift + Enter',
        label: 'Salto de linea'
    }
]


const ChatInput = ({
    send,
    placeholder,
    colorSchemeConfig,
    channel,
    disableChat,
    sendMultimedia,
    handlePressBlockNumber,
    multimedia,
    options
}: ChatInputProps) => {
    const [openScheduledMessage, toggleScheduledMessage] = useToggle<boolean>(false)
    const isReply = useSelector((state: any) => state.crm.reply)
    const { textValue, setTextValue, staticText, prospectId } = useChatInputContext()
    const [openEmojiMenu, setOpenEmojiMenu] = useState<any>()
    const [openFastTemplate, setOpenFastTemplate] = useState<false | any>(false)
    const [recordingAudio, setRecoridingAudio] = useState<boolean>(false)
    const [isActiveTemplate, setIsTemplateActive] = useState<boolean>(false)
    const action = useSelector((state: any) => state.crm.action);
    const isActiveTemplateMode = useSelector((state: any) => state.crm.isActiveTemplateMode);
    const templateSelected = useSelector((state: any) => state.crm.selectedTemplate);
    const matchedTemplate = useSelector((state: any) => state.crm.matchTemplate);
    const scheduleMessageDate = useSelector((state: any) => state.crm.scheduleMessageDate);

    const { getFileBase64 } = useUploadFile({
        addFile: async () => defaultValueForEditor,
    })

    const textareaRef = useRef<HTMLTextAreaElement | null>(null)
    const dispatch = useDispatch()

    const { enabledScheduledMessage } = options || {}

    useEffect(() => {
        if (staticText && textareaRef.current) {
            const length = staticText.length;
            textareaRef.current.setSelectionRange(length, length);
        }
    }, [prospectId])

    const handleMicPress = useCallback(() => {
        setRecoridingAudio(true)
    }, [setRecoridingAudio])

    const handleSend = useCallback((e) => {
        e.preventDefault()
        if (disableChat) return;

        send(e, textValue)
        setTextValue("")
    }, [textValue, scheduleMessageDate])

    const colorScheme = useMemo(() => colorSchemeConfig ? colorSchemeConfig : defaultColorSchemeConfig, [colorSchemeConfig])
    const multimediaFeature = useMemo(() => multimedia ? { ...multimediaFeatureDefaultConfig, ...multimedia } : multimediaFeatureDefaultConfig, [multimedia])

    const handleSendAudio = (file: string, mimetype: string) => {
        sendMultimedia && sendMultimedia(file, "audio", '', 'voice.mp3', mimetype)
        setRecoridingAudio(false)
    }

    const handleAddEmoji = (text: string) => {
        if (textareaRef.current) {
            const newTextValue = addStrToInput(text, textareaRef)
            setTextValue(newTextValue);
        }
    }

    useEffect(() => {
        dispatch(setStaticTexts({ id: prospectId, text: textValue }))
    }, [textValue])

    useEffect(() => {
        if (isActiveTemplate && textValue.length === 0) {
            setIsTemplateActive(false);
        }
    }, [isActiveTemplate, textValue, action]);

    const userTemplates = useMemo(() => {
        const user = isAuth()
        if (user && user.templates) {
            return user.templates.filter((template) => template.channel === 'message')
        }
        return
    }, [])

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (isActiveTemplateMode) {
                if (event.code === 'Space') {
                    dispatch(setTemplateMode(false));
                    setOpenFastTemplate(false);
                    setIsTemplateActive(false);
                    setTextValue('')
                    textareaRef.current?.focus();
                } else if (event.key === 'Enter') {
                    event.preventDefault();
                    dispatch(setTemplateMode(false));
                    setOpenFastTemplate(false);
                    setIsTemplateActive(false);
                    setTextValue(matchedTemplate?.message || '')
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isActiveTemplateMode, templateSelected, matchedTemplate]);

    useEffect(() => {
        if (textValue.length > 0 && textValue[0] == '/' && action !== 'note' && action !== 'select' && !isActiveTemplateMode) {
            dispatch(setTemplateMode(true));
            setOpenFastTemplate(true);
            setIsTemplateActive(true);
        } else if ((textValue.length === 0 || textValue[0] !== '/') && isActiveTemplateMode) {
            dispatch(setTemplateMode(false));
            setOpenFastTemplate(false);
            setIsTemplateActive(false);
        }
    }, [textValue, isActiveTemplateMode, action]);

    useEffect(() => {
        dispatch(setSearchTemplate(textValue));
    }, [textValue]);

    if (openFastTemplate && !isActiveTemplateMode) {
        return (
            <TemplateSelect
                goBack={() => setOpenFastTemplate(false)}
                type="userTemplate"
                channel={channel}
                send={(__, messageSend) => {
                    setOpenFastTemplate(false)
                    setIsTemplateActive(true)
                    setTextValue(messageSend)
                }}
            />
        )
    }

    const handleKeyDownInput = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if ((e.ctrlKey && e.key === 'Enter') || (e.shiftKey && e.key === 'Enter')) {
            if (!textareaRef) return;
            e.preventDefault();
            const newTextValue = addStrToInput('\n', textareaRef)
            setTextValue(newTextValue);
        } else if (e.key === 'Enter' && !isActiveTemplateMode) {
            e.preventDefault();
            if (textValue.length > 0) handleSend(e)
        }
    };

    const onChangeScheduleDate = (date: string) => {
        if (!date) return;
        dispatch(setScheduleMessageDate(date))
    }

    const handlePaste = async (e) => {
        const pastedText = e.clipboardData.getData('text');

        try {
            const response = await axios.get(pastedText, { responseType: 'blob' });
            const blob = response.data;
            const blobType = blob?.type
            if (!blobType) return;

            const file = new File([blob], pastedText.substring(pastedText.lastIndexOf('/') + 1).split('?')[0], { type: blobType });
            const { result = [] } = await getFileBase64([file])

            dispatch(setUploadMultimediaType({
                type: blobType.includes('image') || blobType.includes('video')
                    ? imagesAndVideoMimeType
                    : undefined,
                multimedia: result
            }))

        } catch (error) {
            console.error('Error al obtener el archivo:', error);
        }
    };

    return (
        <>
            <form onSubmit={handleSend} className="h-full relative bg-blue-100">
                {
                    disableChat && <span
                        onClick={() => handlePressBlockNumber && handlePressBlockNumber()}
                        className="w-full h-full bg-white/40 cursor-not-allowed absolute top-0 left-0 z-30" />
                }
                <EmojiMenu addEmoji={handleAddEmoji} openEmojiMenu={openEmojiMenu} setOpenEmojiMenu={setOpenEmojiMenu} />
                <ScheduledModal
                    open={!!openScheduledMessage}
                    onOpenChange={toggleScheduledMessage}
                    onSuccess={onChangeScheduleDate}
                />
                <div
                    className="w-full h-full flex flex-col gap-2 overflow-y-auto"
                    style={{ backgroundColor: colorScheme.background }}
                >
                    {isReply &&
                        <ReplyCard interaction={isReply} />
                    }
                    <div className="pt-2 flex gap-2 flex-grow items-center h-full">
                        <Textarea
                            onKeyDown={handleKeyDownInput}
                            disabled={!!disableChat}
                            onClick={(e) => e.stopPropagation()}
                            placeholder={placeholder ?? "Escribe algo..."}
                            onChange={e => setTextValue(e.target.value)}
                            autoFocus={true}
                            value={textValue}
                            className="border-none placeholder:text-base h-full text-base focus-visible:ring-offset-0 focus-visible:ring-0 min-h-[50px] resize-none overflow-y-auto"
                            ref={textareaRef}
                            onPaste={handlePaste}
                        />
                    </div>
                    <div className="flex justify-between px-2 pb-1">
                        <div className="flex items-center justify-center space-x-1">
                            {
                                (multimediaFeature.file || multimediaFeature.image) && <>
                                    {
                                        disableChat
                                            ? <ButtonActions disabled={!!disableChat}>
                                                <Paperclip size={18} />
                                            </ButtonActions>
                                            : <Popover>
                                                <PopoverTrigger>
                                                    {
                                                        (multimediaFeature.file || multimediaFeature.image) && <div>
                                                            <SimpleTooltip content="Adjuntar archivos">
                                                                <ButtonActions disabled={!!disableChat}>
                                                                    <Paperclip size={18} />
                                                                </ButtonActions>
                                                            </SimpleTooltip>
                                                        </div>
                                                    }
                                                </PopoverTrigger>
                                                <PopoverContent className="w-auto p-[3px]">
                                                    <div className="flex flex-col gap-1">
                                                        {
                                                            multimediaFeature.file && <ButtonFileAndImage
                                                                label={'Fotos y videos'}
                                                                accept={imagesAndVideoMimeType}
                                                                icon={<ImageICon />}
                                                                type={UPLOAD_TYPE.IMAGE_AND_VIDEO}
                                                            />
                                                        }
                                                        {
                                                            multimediaFeature.file && <ButtonFileAndImage
                                                                type={UPLOAD_TYPE.FILE}
                                                                label={'Documentos'}
                                                                icon={<IFile />}
                                                            />
                                                        }
                                                    </div>
                                                </PopoverContent>
                                            </Popover>
                                    }
                                </>
                            }

                            {action !== 'note' && action !== 'select' && <ActionsSeparator />}
                            <SimpleTooltip content="Emojis">
                                <ButtonActions
                                    disabled={!!disableChat}
                                    onClick={(e) => setOpenEmojiMenu(e.currentTarget)}>
                                    <Laugh style={{ color: colorScheme.emojiButton }} size={19} />
                                </ButtonActions>
                            </SimpleTooltip>

                            {(channel && channel.fastTemplates && channel.fastTemplates.length > 0 || userTemplates?.length > 0 && action !== "note" && action !== "select") && (
                                <>
                                    <ActionsSeparator />
                                    <SimpleTooltip content="Plantillas rápidas">
                                        <ButtonActions
                                            disabled={!!disableChat}
                                            onClick={(e) => setOpenFastTemplate(e.currentTarget)}
                                            className={`${isActiveTemplate ? 'hover:text-primary text-primary' : 'hover:text-black/40 text-[#757575]'}`}>
                                            <StickyNote2 />
                                        </ButtonActions>
                                    </SimpleTooltip>
                                </>
                            )}
                        </div>
                        <div>
                            {(multimediaFeature.voice && textValue.length == 0)
                                ? <AudioRecorder onSendAudio={handleSendAudio} />
                                : (
                                    <div className="flex flex-row items-center gap-1 h-full">
                                        {
                                            enabledScheduledMessage && <>
                                                {scheduleMessageDate ? (
                                                    <BadgePill className="bg-gray-100">
                                                        <span className="flex flex-row gap-1 items-center">
                                                            <Send className="text-primary" size={12} />
                                                            <p className="text-primary text-[12px]">
                                                                {`Programado ${moment(scheduleMessageDate).format('DD MMM h:mm a')}`}
                                                            </p>
                                                            <X className="cursor-pointer hover:text-primary" size={12} onClick={() => dispatch(setScheduleMessageDate(null))} />
                                                        </span>
                                                    </BadgePill>
                                                ) : (
                                                    <SimpleTooltip
                                                        content="Programar mensaje"
                                                    >
                                                        <ButtonActions
                                                            disabled={!!disableChat}
                                                            onClick={() => {
                                                                mixpanel.track('Schedule message', {
                                                                    userId: isAuth()?._id,
                                                                    company: isAuth()?.group?._id
                                                                })
                                                                toggleScheduledMessage()
                                                            }}
                                                        >
                                                            <CalendarClock
                                                                size={19}
                                                                style={{
                                                                    color: colorScheme.emojiButton
                                                                }}
                                                            />
                                                        </ButtonActions>
                                                    </SimpleTooltip>
                                                )}
                                                <ActionsSeparator />
                                            </>
                                        }
                                        <ToolTipAndAction
                                            className="right-3 relative"
                                            actions={detailInput}
                                        >
                                            <Button
                                                disabled={textValue.length == 0 || !!disableChat}
                                                className="w-full border-none bg-transparent hover:bg-transparent"
                                                variant={'outline'}
                                                type="submit"
                                            >
                                                <p className="text-[14px] hover:opacity-80"
                                                    style={{ color: colorScheme.submitButton }}>
                                                    Enviar
                                                </p>
                                            </Button>
                                        </ToolTipAndAction>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>

            </form>
        </>
    )
}


interface ButtonActionsProps {
    children: ReactNode,
    className?: string,
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void
    disabled?: boolean
}

export function ButtonActions({
    children,
    className,
    onClick,
    disabled = false
}: ButtonActionsProps) {
    return (
        <Button
            size={'icon'}
            disabled={disabled}
            type="button"
            onClick={(e) => onClick && onClick(e)}
            variant={'ghost'}
            className={cn(`text-[#757575] hover:bg-black/5 hover:text-black/40`, className)}
        >
            {children}
        </Button>
    )
}

export const ActionsSeparator = () => <Separator orientation="vertical" className="h-[50%] bg-[#C4C4C4]" />

export function ButtonFileAndImage({
    label,
    icon,
    accept = '',
    type
}: PropsButtonFile) {
    const { getInputProps, open, result: multimedia, filesRemoves } = useUploadFile({
        addFile: async () => defaultValueForEditor,
        accept
    })

    const { toast } = useToast();
    const dispatch = useDispatch()

    useEffect(() => {
        if (!!filesRemoves && filesRemoves > 0) {
            toast({
                title: 'Archivos removidos',
                description: `${filesRemoves}${filesRemoves >= 2 ? ' archivos han sido removidos' : ' archivo ha  sido removido'} debido a que su tamaño supero el limite`,
                duration: 3000,
            })
        }

        if (multimedia) {
            dispatch(setUploadMultimediaType({ type, multimedia }))
        }
    }, [multimedia])

    return <>
        <input
            type="file"
            hidden
            multiple={true}
            {...getInputProps()}
        />

        <Button
            variant={'outline'} className="w-full [&>svg]:w-[17px] [&>svg]:h-[17px] border-none flex gap-2 items-center justify-start px-3 text-[13px] h-8 rounded-sm" onClick={() => open()}>
            {icon}
            {label}
        </Button>
    </>
}

export default ChatInput