export const regexVariable = /{{(.*?)}}/g

export function replaceObjectValues(template, fields) {
    return template.replace(regexVariable, (match, p1) => {
        let keys = p1.split('.');
        let currentField = fields;

        for (const key of keys) {
            if (/\[\d+\]/.test(key)) {
                const [fieldName, index] = key.split(/\[|\]/).filter(Boolean);
                currentField = currentField[fieldName];
                if (Array.isArray(currentField)) {
                    currentField = currentField[parseInt(index)];
                } else {
                    return '';
                }
            } else {
                currentField = currentField[key];
            }
            
            if (currentField === undefined) {
                return '';
            }
        }

        return typeof currentField === 'string' ? currentField : '';
    });
}
