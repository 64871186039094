import { TypesOfTemplate } from "@/components/company/uploadExel/libs/enums";
import { IAgentData } from "@/components/crm/Bubble/TransferBubble";
import { contactStatusTagColorArray, contactStatusTagTypeArray } from "@/helpers/tagColorToPalette";
import { User } from "@/models/User";
import { LocalStatus } from "@/redux/libs/ts/interfaces";
import { AvTimer, Campaign, Celebration, Dangerous, DirectionsWalk, SentimentDissatisfied, SentimentSatisfied, SentimentSatisfiedAlt, SentimentVeryDissatisfied, SentimentVerySatisfied, TextSnippet } from "@mui/icons-material";
import { Dayjs } from "dayjs";
import { ReactNode } from "react";
import { TYPES_CHART } from "./config";
import {
  Archiving,
  ChannelMessage,
  ContactTakenBase,
  DateBase,
  DisregardedBase,
  EmailBase,
  ExpiredMeeting,
  MeetingBase,
  NoteBase,
  PhoneCall,
  Reminder,
  ReminderMeetingBase,
  SMS,
  SaleUpdate,
  ScheduledMessage,
  SubConversationClosedBase,
  SubConversationCreatedBase,
  TagBase,
  TransferPopulated,
  UnOfficialWhatsappBase,
  Visit,
} from "./crm/contact";
import mongoose from "mongoose";

export interface Instance {
  title: string;
  body: string;
  requiresResponse: boolean;
  contacts: string[];
  user: string;
  type: 'evaluation' | 'warning' | 'late' | 'miss' | 'incentive' | 'visit';
}

export interface InstanceWithUser extends Omit<Instance, 'user'> {
  user: InstanceUser;
  seenByUser?: boolean;
  createdBy: InstanceUser;
  createdAt?: string;
  updatedAt?: string;
  _id: string;
  comments: Array<any>;
}

export interface InstanceUser extends BasicUser {
  email?: string;
  phone?: string;
}


export interface BasicUser {
  name?: string;
  lastname?: string;
  _id?: string;
}

export interface InstanceError {
  title: boolean | string;
  body: boolean | string;
  requiresResponse: boolean | string;
  contacts: boolean | string;
  user?: boolean | string;
  type?: boolean | string;
}


export enum InterfaceType {
  evaluation = 'evaluacion',
  warning = 'advertencia',
  late = 'llegada tarde',
  miss = 'falta',
  incentive = 'estimulo',
  visit = 'visita'
}


export enum evaluationKey {
  contact = 'contactos',
  tracing = 'seguimientos',
  sale = 'ventas'
}

interface InterfaceTypeCompleteElement {
  name: string;
  //   icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
  //     muiName: string;
  // };
  icon: any;
  color: string;
}


export interface evaluationItem {
  key: 'sale' | 'tracing' | 'contact';
  title: string;
  rating: number;
  body: string;
}

export enum evaluationKeys {
  sale = 'Ventas',
  tracing = 'Seguimientos',
  contact = 'Contactos'
}

export interface Evaluation {
  title: string;
  user: string;
  type: "evaluation";
  evaluations: evaluationItem[];
  requiresResponse: boolean;
  requiresSelfEvaluation: boolean;
}

export interface EvaluationWithUser extends Omit<Evaluation, 'user'> {
  user: InstanceUser;
  seenByUser?: boolean;
  body?: string;
  createdBy: InstanceUser;
  createdAt?: string;
  updatedAt?: string;
  _id: string;
  comments: Array<any>;
}

export interface evaluationItemError {
  key: string;
  rating: boolean | string;
  body: boolean | string;
}

export interface EvaluationError {
  title: boolean | string;
  user?: boolean | string;
  evaluations: evaluationItemError[];
}

export interface PropsCardBubbleUser {
  children: ReactNode
  origin?: 'agent' | 'contact' | undefined
  styleCard?: Record<string, any>
  agent: any
  classNameInteractionStatus?: string
  state?: LocalStatus
  classNameCard?: string
  className?: string
  retryFn?: () => void,
  messageError?: string,
  classNameToCard?: string
  channel?: string
  profile?: string | null | undefined
}

export interface Agent extends User {
  _id: string
}

export type AgentOrNull = Agent

export const InterfaceTypeComplete = {
  evaluation: { name: 'evaluacion', icon: TextSnippet, color: "#9E9E9E" } as InterfaceTypeCompleteElement,
  warning: { name: 'advertencia', icon: Campaign, color: "#F44336" } as InterfaceTypeCompleteElement,
  late: { name: 'llegada tarde', icon: AvTimer, color: "#FFC107" } as InterfaceTypeCompleteElement,
  miss: { name: 'falta', icon: Dangerous, color: "#FF5722" } as InterfaceTypeCompleteElement,
  incentive: { name: 'estimulo', icon: Celebration, color: "#4CAF50" } as InterfaceTypeCompleteElement,
  visit: { name: 'visita', icon: DirectionsWalk, color: "#0080ff" } as InterfaceTypeCompleteElement,
}



export const evaluationRating = {
  0: {
    icon: SentimentSatisfied,
    label: 'sin calificar',
    color: 'default'
  },
  1: {
    icon: SentimentVeryDissatisfied,
    label: "deben mejorar",
    color: 'error'
  },
  2: {
    icon: SentimentDissatisfied,
    label: "pueden mejorar",
    color: 'warning'
  },
  3: {
    icon: SentimentSatisfied,
    label: "son correctos",
    color: 'info'
  },
  4: {
    icon: SentimentSatisfiedAlt,
    label: "son buenos",
    color: 'success'
  },
  5: {
    icon: SentimentVerySatisfied,
    label: "excelentes",
    color: 'secondary'
  },
};

export interface IUserByRol {
  _id: string,
  lastname: string,
  name: string,
  photo: string
}

export interface PerformanceCardInterface {
  selectedUser?: any;
  chartContainerRef?: any;
  activeLine?: boolean
  chartType: TYPES_CHART,
  user?: string | null | undefined
  active?: boolean
  periodSelected: TPeriod
  title: string;
  goalExpected: number
  goalsAchieved: Record<string, number>
  startAndEndGoal: {
    start: Date | string,
    end: Date | string
  }
  goalPlus?: number;
  goalValue: number;
  generated: boolean;
  onClick: () => void;
}

export interface EditSellerPopoverProps {
  contactId: string
  listOfUsers: IUserByRol[]
  agentsDictionaryList: Record<string, IUserByRol>
  agent: IUserByRol
}


export interface PerformanceInterface {
  user: string;
  selectedUserSyncHistory: User;
  group?: any;
}
export type PropsTab = {
  readonly value: string,
  readonly label: string
}
export type TypeModal = 'contacts' | 'tracing' | 'sale'
export type TPeriod = 'now' | 'before'

export interface ProspectProps {
  id: string
  forceBack?: boolean
  className?: string
  classFoceBack?: string,
  query?: Record<string, any>
  fixedInformationMenu?: boolean
  handleAfkError?: () => void
  onClose?: () => void
  countUnassigned?: number
  customStylesBubbles?: TCustomStyles | null
  agentSet?: () => void
  mode?: "default" | "unassigned"
  nextProspect?: () => void
  enableNextProspect?: boolean
  isFromSharedInbox?: boolean
}

export enum UPLOAD_TYPE {
  IMAGE_AND_VIDEO = 'upload_image_and_video',
  FILE = 'upload_file',
}


interface IUrlFetch {
  path: string,
  isExternalUrl?: boolean,
  documentsPath?: string
}
export interface IURLsFetchers {
  upload: IUrlFetch,
  preview: IUrlFetch
}

export type TemplateType = 'sales' | 'calls' | 'companygroup' | 'campaigns-contact' | 'contacts'
export interface IPropsContentSteps {
  type: TypesOfTemplate,
  isUploadContacts?: boolean
  fromUser?: boolean
  columns: Array<any>
  titles: string[]
  defaultTemplate: any | Array<Record<string, string[]>>
  dataSteps: { title: string; description: string; icon: ReactNode; }[]
  name: string
  urlsFetchers?: Partial<IURLsFetchers>
  asynFiles?: boolean
  preview?: boolean,
  onCloseTable?: () => void
}

export interface PropTableSale {
  urlsFetchers?: IURLsFetchers,
  isUploadContacts?: boolean
  name: string
  defaultTemplate: any
  onClose?: () => void
}

export type CustomReminder = {
  type: "phoneCall" | "message" | "visit" | "meeting" | "other"
  step: 0 | 1
  bgColor: string
  textColor: string
  activeGoBack: boolean
  label: string
  icon: ReactNode
  title: ReactNode | string
}
export interface ModalReminderProps {
  open: Array<string> | string | boolean | null
  onCLose: () => void
  dateOptions?: DateItem[] | null,
  handleSuccess?: () => void
  className?: string
  customReminder?: null | CustomReminder
}

export type DateOption = {
  date: [number, string];
  functionsToApply?: Array<(date: Dayjs) => void>
  title: string;
};

export interface ArchiveDrawerInterface {
  open: any;
  onClose: () => void;
  reminder?: boolean
  handleSuccess?: () => void;
  disableArchivingReasons?: string[],
  currentArchivingReason?: null | string
}

export type DateItem = {
  title: string;
  format: string;
  isCurrentDay?: boolean;
  options: DateOption[];
};

export type TCustomStyles = {
  note?: string
  date?: string
  "unofficial-whatsapp"?: string
  endMessage?: string | null
  reminder?: string
  meeting?: string
  sms?: string
  phoneCall?: string
  channelMessage?: string
  email?: string
  saleupdate?: string
}

export type InteractionType =
  | Archiving
  | PhoneCall
  | TransferPopulated
  | NoteBase
  | Reminder
  | Visit
  | SaleUpdate
  | ExpiredMeeting
  | ChannelMessage
  | UnOfficialWhatsappBase
  | ContactTakenBase
  | DateBase
  | EmailBase
  | SubConversationCreatedBase
  | SubConversationClosedBase
  | TagBase
  | DisregardedBase
  | MeetingBase
  | ReminderMeetingBase
  | SMS
  | ScheduledMessage

export interface ConversationInterface {
  interaction: InteractionType
  notResponseCall?: number
  customStyles?: TCustomStyles | null
  agent: any
}


export type IInteractionContent = {
  actionType?: string
  comment?: string
  reminderDate: string
}

export type IInteraction = {
  via: string,
  content: any,
  agent: AgentOrNull | IAgentData
  createdAt: string
  _id?: string
  localStatus?: LocalStatus | null,
  contact?: string
}

export type channelConfigTypes = "whatsapp" | "facebook" | "visit" | "default"

export interface IReminderBubble {
  agent?: AgentOrNull | IAgentData
  _id: string
  interaction: IInteraction
  profile?: string
  customStyle?: string
  contact?: string
  via: string
}

export interface INoteBubble {
  customStyle?: string | null
  profile: string | null
  noteType?: 'note' | 'visit'
  interaction: IInteraction
  channel?: channelConfigTypes
  origin?: "contact" | "agent"
  subTitle?: string
}

export interface SmsBubbleProps {
  customStyle?: string | null,
  profile: string,
  interaction: any
}

export interface ContactCampaign {
  _id: string
  contact: string
  company: string
  campaignId: string
  medium: string
  status: 'active' | 'cancel' | 'finished' | 'paused' | 'error'
  step?: string
  lastDateSend?: string
  nextSendDate: string
  stop: boolean
}

export interface ModalPropsBase {
  open: boolean,
  onOpenChange: (value: boolean) => void
}

export interface IDashboardChart {
  _id?: string
  name: string,
  description?: string,
  company: string,
  referenceToDefaultChart?: string,
  hidden?: boolean,
  show?: boolean,
  assignedTo: 'all' | 'group' | 'user',
  assignedUsers: string[]
  assignedGroups: string[]
  charts: {
    chartId:
    'contactsCreated'
    | 'contactsTaken'
    | 'tracings'
    | 'calls'
    | 'minutesOnAir'
    | 'redesChats'
    | 'whatsAppChats'
    | 'contactAttention'
    | 'visits'
    | 'whatsAppNotes'
    | 'dailySalesSinceInception'
    | 'receipts'
    | 'salesEntered'
    | 'salesByAttention'
    | 'channelSales'
    | 'salesPerVisit'
    | 'salesType'
    | 'salesByDepartment'
    | 'salesByCity'
    | 'salesByProduct'
    | 'salesByCatagorie'
    | 'totalClient'
    | 'closures'
    | 'closuresByChannel'
    | 'closuresPerContactAttention'
    | 'closuresPerDayInception',
    properties?: Record<string, string | number>
    index: number,
    show: boolean
  }[]
}

export interface ICompanyGroup {
  _id: string
  displayName: string;
  businessName: string;
  description: string;
  additionalData?: Record<string, any>,
  origin: string;
  taxCode: string;
  direction: any;
  sectors: boolean
  company: string;
  createdBy: {
    _id: string,
    name: string,
    lastname: string,
    photo: string
  };
  status: 'active' | 'inactive';
}

export interface ICompanySector extends Document {
  _id: string
  name: string,
  company: string
  companyGroup: string
}


export interface IGoogleCalendar {
  email: string,
  _id: string
}

export interface IZoom {
  email: string,
  _id: string
}

export interface StatusTag {
  name: string;
  code: string;
  active: boolean;
  group: string
  ghost: boolean;
  type: typeof contactStatusTagTypeArray[number] | '',
  color: typeof contactStatusTagColorArray[number] | '',
  usersFilters?: {
    fieldCode: string,
    values: any
  }[];
  groupFilters?: string[] | mongoose.Types.ObjectId[]
}

export interface ContactFromFields {
  index: number,
  title?: string,
  description?: string,
  placeholder?: string,
  reference: string
}
export interface IContactForm {
  _id: string,
  company?: string,
  title: string,
  description?: string,
  assignedTo?: string[],
  fields: ContactFromFields[],
  usersFilters: {
    fieldCode: string,
    values: any
  }[]
}

export interface ScheduleMessageContent {
  interactionType: 'unofficial-whatsapp',
  channel?: string,
  date: string,
  messageContent: Partial<{
    message: string,
    media: string,
    mediaType: "image" | "video" | "audio" | "file",
    mimetype: string,
    filename: string
  }>
}

export type NextActionsBeforeArchiveType = 'persistentReminder' | 'standardReminder' | 'noAction' | 'transfer' | 'changeStatusTag'
export interface ArchivingReason {
  _id: string,
  name: string
  code: string
  ghost: boolean
  active: boolean
  globalActive?: boolean
  type: string,
  limitedToRoles?: string[]
  groupCode?: string,
  permanent?: boolean,
  nextAction: NextActionsBeforeArchiveType,
  clientInteraction: {
    unarchivingByClientInteraction: boolean,
    timestamp?: number
  },
  group: string | {
    _id: string,
    name: string,
    displayName: string
  }
}
export interface ArchivingReasonsGroup {
  name: string,
  description?: string,
  code: string,
  icon?: {
    type: 'icon' | 'link',
    url: string
  }
}

export type AdditionalCompanyFieldsTypesType = 'date' | 'number' | 'string' | 'multiselect' |
  'select' | 'selectcontact' | 'multiplecontact' |
  'selectcompany' | 'multiplecompany' | 'phonenumbers';
export interface AdditionalCompanyField {
  name: string,
  code: string,
  type: AdditionalCompanyFieldsTypesType,
  active: boolean,
  ghost: boolean,
  defaultValue: any,
  required: boolean,
  options?: any[];
}

export interface IAdditionalUserField {
  title: string;
  key: string;
  type: "string" | "number" | "select";
  roles: ("user" | "super" | "comercial" | "users_manager" | "admin")[];
  options?: string[];
}

export const defaultFieldsToHide = ['displayName', 'origin', 'taxCode', 'direction'] as const;
export type CompanyGroupFieldsKey = typeof defaultFieldsToHide[number]

export interface DefaultFieldCompanyGroup {
  key: CompanyGroupFieldsKey,
  hidden: Boolean,
  defaultValue: any
}

export interface CompanyGroupSettings {
  title: {
    singular: string,
    plural: string
  },
  icon: {
    medium: 'lucide' | 'remoteUrl',
    path: string
  },
  defaultFields: DefaultFieldCompanyGroup[]
}

export interface IOrigin {
  name: string;
  code: string;
  active: boolean;
  globalActive?: boolean;
  group: string,
  ghost: boolean;
  usersFilters?: {
    fieldCode: string,
    values: any
  }[]
}

interface AgentOfSharedContactPortfolio {
  disregardedCount?: number
  lastname: string,
  name: string,
  _id: string,
  photo: string,
  email: string,
  config?: {
    enablePersonalWhatsapp?: boolean
  },
}

export interface IntervalDateValue {
  hour: number,
  minute: number
}

export interface ISharedContactsInbox {
  _id: string,
  name: string,
  active: boolean,
  activeNow?: boolean,
  company: string,
  accessAgents: AgentOfSharedContactPortfolio[],
  accessedAgents: AgentOfSharedContactPortfolio[],
  weeklyAccessDate: {
    active: boolean,
    intervalTimes: {
      start: IntervalDateValue,
      end: IntervalDateValue
    }[]
  }[]
}

export interface IRoundRobbinBase {
  type?: 'normal' | 'online';
  name: string;
  company: string;
  channels?: string[];
  agents: string[];
  active: boolean;
  userAdditionalFilter?: {
    fieldCode: string;
    values: any;
  }[];
}

export interface IRoundRobbin extends IRoundRobbinBase {
  type: 'normal';
  position: number;
}

export interface IOnlineRoundRobbin extends IRoundRobbinBase {
  type: 'online';
  takeEnabledUsers: number;
  lastUsersExpand: Date;
  takeContactList: string[];
  enableUserFiltersList: boolean;
  queuedUsers: {
    user: string;
    lastConnectionDate: Date;
  }[];
}

export interface OnlineRoundRobbinState {
  takeEnabledUsers: number,
  queuedUsersCount: number,
  userPosition: number,
  enabledToMakeContact: boolean
}

export interface IActiveOnlineRoundRobbin extends OnlineRoundRobbinState {
  _id: string
}

const notificationStates = ['idle', 'sent', 'waiting'] as const;
type NotificationStateType = typeof notificationStates[number];

const states = ['uknown', 'creating', 'created', 'qr', 'connected', 'error', 'timeout', 'closed', 'registerSessionError', 'registrationCodeNotProvided', 'invalidPhoneNumber', 'registerMccManually', 'requestCaptchaCode', 'phoneNumberNotProvided', 'invalidCaptchaCode', 'requestRegistrationCodeError'] as const;
export type SessionState = typeof states[number];

export interface ChannelWhatsappSession {
  _id: string
  phoneNumber?: string,
  name?: string,
  loggedFrom?: string,
  isOnline: boolean,
  enabled: boolean,
  qrCode: string,
  lastSessionDate: Date,
  type: 'channel' | 'personal' | 's-personal',
  channel?: string,
  status: SessionState,
  notificationState: NotificationStateType,
  disconnectionDate: Date,
  publicUrl?: string,
  lastMessage?: Date,
  connection: {
    useMobile?: boolean,
    mobileMetadata: {
      registerCode?: string,
      otpMethod?: 'sms' | 'voice',
      registrationError?: {
        reason: string,
        status: string,
        retry_after: number,
        sms_wait: number,
        voice_wait: number,
        date: Date,
      },
      captcha?: Partial<{
        path: string,
        code: string,
        date: Date
      }>
    }
  }
}

const actionsToExecute = ['call', 'message', 'not_action'] as const;
type ActionToExecuteType = typeof actionsToExecute[number];

export interface ScoreRangeConfig {
  range: {
    min: number, max: number
  },
  clientResponse: {
    responseMessage: string,
    action: {
      type: ActionToExecuteType,
      after: { minutes: number }
    }
  }
}

export interface INetPromoterScore {
  _id: string,
  active: boolean,
  name: string,
  description: string,
  config: {
    exitKeyword: string,
    messageConfigs: {
      initialMessage: string,
      invalidValueMessage: string,
    },
    triggers: Array<{
      type: 'archive' | 'reminder' | 'statusTag' | 'visit'
    }>,
    scale: number,
    scoresRangeConfig: ScoreRangeConfig[],
    delay: {
      unit: string,
      value: number
    }
  }
}

export interface NpsPercentage {
  responseRate: number,
  completionRate: number,
  promoters: number,
  detractors: number,
  passives: number
}
export interface NetPromoterScoreStats {
  dailyBreakdown: {
    dailyCompletionRate: number,
    dailyResponseRate: number,
    dailyResponseCount: number,
    dailySurveySent: number,
    detractorCount: number,
    promoterCount: number,
    passiveCount: number,
    date: Date,
    formatedDate: string,
    dailyNPS: number,
    percentages: NpsPercentage
  }[],
  overall: {
    npsScore: number,
    promoterCount: number,
    detractorCount: number,
    passiveCount: number,
    totalResponses: number,
    totalDismissed: number,
    totalSurveys: number,
    surveyAbandonmentRate: number,
    surveyErrors: number,
    surveysRatePerDay: number,
    totalWithoutResponses: number,
    totalSurveyCompleted: number,
    satisfactionIndex: number,
    totalSurveysSended: number,
    actions: {
      completed: number,
      totalCanceled: number,
      totalPending: number
    },
    percentages: NpsPercentage
  }
};


export type SurveyStatus = 'pendingDispatch' | 'error' | 'sended' | 'dismissed' | 'completed';
export type ActionSurveyStatusType = 'todo' | 'doing' | 'canceled' | 'done';

export interface INetPromoterScoreSurvey {
  _id: string;
  sendedDate: string;
  completedDate?: string;
  scale: number;
  sendSurveyDate?: string,
  response?: number;
  actionDueDate: string;
  action: 'call' | 'message' | 'not_action';
  surveyStatus: SurveyStatus;
  actionStatus: 'todo' | 'doing' | 'canceled' | 'done';
  contact: {
    firstName: string,
    lastName: string,
    _id: string,
    statusTag: string
    photo: string
  }
  createdAt: string,
  updatedAt: string,
  logs: {
    text: string,
    date: string,
    errorCode?: string
  }[]
}

export interface SessionWhatsappCampaignChannel {
  _id: string,
  isOnline: string,
  status: string,
  qrCode: string,
  name: string,
  phoneNumber: string,
  whatsappLine?: {
    campaigns: {
      firstContactMessages: {
        count: number,
        resetDate: string
      }
    }
  },
  config: {
    notification: {
      notifyByPlatform: boolean,
      notifyByEmail: boolean,
      emails: string[]
    }
  }
}

export interface CampaignsGroupConfig {
  isActive: boolean,
  settings: {
    whatsappCampaign: {
      maxMessagesPerSession: number
    }
  }
}

export const commisionsStatus = ['pending', 'rejected', 'confirmed', 'draft'] as const;
export type ComisionsStatusType = typeof commisionsStatus[number];

export interface ComissionInterface {
  _id: string;
  category: string,
  bounty: number,
  bountyCurrency: string,
  totalBounty: number,
  status: ComisionsStatusType,
  rejectionReason: string,
  sales?: number,
  createdAt: string,
  updatedAt: string,
  createdBy?: {
    name: string,
    lastname: string,
    photo?: string,
    _id: string
  }
  source: string,
  splitDeal: {
    user: {
      name: string,
      lastname: string,
      photo?: string,
      _id: string
    },
    percentage: number
  }[]
};

export interface ComissionType {
  title: string,
  code: string,
  color: string,
  active: boolean
};

export const groupingTypes = ['monthly'] as const;
export type GroupingTypes = typeof groupingTypes[number];

export interface ComissionPolicy {
  activeDate: Date,
  groupingType: GroupingTypes,
  content: { [key: string]: any },
  type: 'code'
  _id: string
};

export interface AccountExecutive {
  _id: string,
  name: string,
  lastname: string,
  meetLink: string,
  email: string,
  photoPath: string
};