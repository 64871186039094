import { getCompany, isAuth } from "@/actions/auth"
import { ICreateLocalInteraction, LocalStatus } from "@/redux/libs/ts/interfaces"
import _ from "lodash"

export function* createLocalInteraction({
    content,
    via = 'web',
    type,
    status = LocalStatus.LOADING,
    contact,
    id,
    externalAgent
}: ICreateLocalInteraction) {
    console.log(externalAgent,'<externalAgent')
    const { _id, name, photo, lastname, email } = externalAgent || isAuth() || {}
    const company = _.get(getCompany(), '_id', '')

    yield {
        content,
        company,
        contact,
        status: 'started',
        _id: id,
        via,
        type,
        localStatus: status,
        createdAt: new Date().toISOString(),
        updateAt: new Date().toISOString(),
        agent: {
            _id,
            name,
            photo,
            lastname,
            email
        }
    }
}

export const getOriginsFiltered = () => {
    const originsList = _.get(isAuth(), 'group.origins', []).filter((origin) => origin['active']);

    const additionalUsersFields = _.get(getCompany(), 'additionalUserFields', [])
    const companyAdditionalDataToMap = _.keyBy(additionalUsersFields, 'key')
    const userAdditionalFields = _.get(isAuth(), 'additionalData', [])

    return originsList.filter(({ usersFilters = [] }) => {
        if (usersFilters.length == 0) return true;

        return usersFilters.every(({ fieldCode, values }) => {
            const userValue: any = userAdditionalFields[fieldCode];
            const fieldData = companyAdditionalDataToMap[fieldCode];

            if (!values || !fieldData) return true;

            let isMatched: boolean = true;

            switch (fieldData.type) {
                case 'number': {
                    const parsedValue = parseInt(values);
                    if (isNaN(parsedValue) || parsedValue != userValue) {
                        isMatched = false;
                    }
                    break;
                }
                case 'string': {
                    if (typeof values !== 'string' || values !== userValue) {
                        isMatched = false;
                    }
                    break;
                }
                case 'select': {
                    const valuesList: any[] = [values].flat();

                    if (!valuesList.includes(userValue)) {
                        isMatched = false;
                    }
                    break;
                }
            }

            return isMatched;
        })
    })
}