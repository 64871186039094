import { getAllCompanies, getCompanyUsers, getOtherCompanyUsers } from "@/actions/admin"
import { authenticate, changeToGhostAdmin, getCookie, isAuth, setCookie, signin_push } from "@/actions/auth"
import { Avatar } from "@/components/ui/avatar"
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { Separator } from "@/components/ui/separator"
import { ArrowLeft, Building2, Eye, Flag, Loader2, Search, User } from "lucide-react"
import "moment/locale/es"
import { use, useEffect, useMemo, useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { UserListItem } from "./MyCompanyModal"
import { Button } from "@/components/ui/button"
import Link from "next/link"
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/components/ui/tooltip"
import { useRouter } from "next/router"


interface CompanyModalProps {
    dialogTrigger: React.ReactElement
}


export const OtherCompanyModal = ({ dialogTrigger }: CompanyModalProps) => {
    const [searchText, setSearchText] = useState('');
    const [tab, setTab] = useState<'groups' | 'users'>('groups');
    const [id, setId] = useState<string>()
    const [open, setOpen] = useState(false)

    const { data: allGroups, isLoading } = useQuery({
        queryFn: () => getAllCompanies(),
        queryKey: ['get-all-groups']
    })

    const { data: allOtherCompanyUsers, isLoading: loadingOtherCompanyUsers } = useQuery({
        queryFn: () => getOtherCompanyUsers(id),
        queryKey: ['get-other-company-users'],
        enabled: !!id
    })


    const filteredGroups = useMemo(() => {
        let groupsList = allGroups?.data ?? []

        if (searchText) {
            groupsList = groupsList.filter((group) => {
                const searchWords = searchText.toLowerCase().split(' ');

                return searchWords.every((word) =>
                    [group.name, group.lastname, group.email].some((field) => field?.toLowerCase().includes(word))
                );
            });
        }

        return groupsList.sort((a, b) => a.name.localeCompare(b.name))
    }, [allGroups, searchText])


    const router = useRouter()
    const filteredUsers = searchText ? allOtherCompanyUsers?.data?.filter((user) => {
        const searchWords = searchText.toLowerCase().split(' ');

        return searchWords.every((word) =>
            [user.name, user.lastname, user.email].some((field) => field?.toLowerCase().includes(word))
        );
    }) : allOtherCompanyUsers?.data;

    var route = useRouter()

    const handleClose = () => {
        setOpen(false)
    }
    const loginPush = (email) => (e) => {
        e.preventDefault();
        signin_push({ email })
            .then((res) => {
                if (res.error) return console.log("error haciendo login");
                if ((isAuth()?.roles ?? []).includes('admin')) setCookie("admin_token", getCookie("token"));
                authenticate(res, () => {
                    handleClose()
                    if (Object.keys(route.query).length > 0) {
                        route.push(route.pathname);
                        setTimeout(() => {
                            window.location.reload();
                        }, 100);
                    } else {
                        route.push('/');
                        route.reload();
                    }
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        setSearchText('')
    }, [open, tab])

    const projectManagerUser = useMemo(() => filteredUsers?.find(user => user.roles.includes("project_manager")), [filteredUsers])

    return (
        <Dialog open={open} onOpenChange={(isOpen) => {
            setSearchText('')
            setOpen(isOpen)
        }}>
            <DialogTrigger asChild>
                {dialogTrigger}
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Cambiar cuenta desde otra empresa</DialogTitle>
                    <DialogDescription>
                        Selecciona el grupo que deseas ver los usuarios para cambiar de cuenta.
                    </DialogDescription>
                </DialogHeader>
                <div className="flex gap-1 items-center">
                    <Input
                        type="text"
                        placeholder={tab === 'groups' ? "Buscar grupo..." : "Buscar usuario..."}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        className="p-2 border rounded focus-visible:ring-0 bg-[#F5F5F5] text-[12px]"
                    />
                </div>
                {tab === 'groups' ? (
                    isLoading ? (
                        <Loader2 className="mx-auto mt-5 animate-spin text-primary" size={30} />
                    ) : (
                        <div className="flex flex-col gap-2 max-h-[350px] overflow-y-auto border p-2 rounded">
                            {filteredGroups && filteredGroups.length > 0 ? (
                                filteredGroups.map((group, index) => (
                                    <div key={index} className="relative">
                                        <div className="absolute top-0.5 right-1 bottom-0">
                                            <TooltipProvider>
                                                <Tooltip delayDuration={1}>
                                                    <TooltipTrigger asChild>
                                                        <Button variant="ghost" className="flex gap-1 cursor-pointer w-fit justify-start p-2 hover:text-primary"
                                                            onClick={() => {
                                                                if (isAuth() && !isAuth().roles.includes('admin')) {

                                                                    changeToGhostAdmin(() => {
                                                                        router.push(`/comercial/${group._id}`)
                                                                        setOpen(false)

                                                                    })
                                                                    return
                                                                } else {
                                                                    setOpen(false)
                                                                    router.push(`/comercial/${group._id}`)
                                                                }
                                                            }}
                                                        >
                                                            <Eye size={18} />
                                                        </Button>
                                                    </TooltipTrigger>
                                                    <TooltipContent>
                                                        <p>Ver empresa</p>
                                                    </TooltipContent>
                                                </Tooltip>
                                            </TooltipProvider>
                                        </div>
                                        <div
                                            className="flex gap-2 hover:bg-slate-100 items-center p-2 cursor-pointer rounded"
                                            onClick={() => {
                                                setId(group._id)
                                                setTab('users')
                                            }}
                                        >
                                            <Avatar className="flex h-7 w-7 border border-slate-400 items-center justify-center">
                                                {group.country ? (
                                                    <img className='w-5 h-4' src={`https://flagcdn.com/w40/${group.country}.png`} alt="flag" />
                                                ) : (
                                                    <Flag size={18} />
                                                )}
                                            </Avatar>
                                            <div className="flex flex-col">
                                                <span>{`${group.name}`}</span>
                                            </div>
                                        </div>
                                        {index !== filteredGroups.length - 1 && <Separator orientation="horizontal" className="w-full mt-2" />}
                                    </div>
                                ))
                            ) : (
                                <div className="flex flex-col items-center justify-center">
                                    <span className="text-[#77797d] text-[12px]">No se encontró el grupo que buscabas...</span>
                                </div>
                            )}
                        </div>
                    )
                ) : (loadingOtherCompanyUsers ? (
                    <Loader2 className="mx-auto mt-5 animate-spin text-primary" size={30} />
                ) : (
                    <>
                        <div className="flex flex-col gap-2 max-h-[350px] overflow-y-auto border p-2 rounded">
                            {filteredUsers && filteredUsers.length > 0 ? (
                                filteredUsers.map((user, index) => {
                                    return (
                                        <div key={index}>
                                            <UserListItem user={user} onClose={handleClose} />
                                            {index !== filteredUsers.length - 1 && <Separator orientation="horizontal" className="w-full mt-2" />}
                                        </div>
                                    )
                                })
                            ) : (
                                <div className="flex flex-col items-center justify-center">
                                    <span className="text-[#77797d] text-[12px]">No se encontró el usuario que buscabas...</span>
                                </div>
                            )}
                        </div>
                        {tab !== 'groups' && <Button disabled={!projectManagerUser} onClick={loginPush(projectManagerUser?.email)}>Usar como Gestor de Proyecto</Button>}

                        <Button variant="link"
                            onClick={() => {
                                setTab('groups')
                                setId(undefined)
                            }}
                            className="flex gap-1 cursor-pointer w-fit justify-start p-0"><ArrowLeft size={14} />Ir atras</Button>
                    </>
                )
                )}
            </DialogContent>
        </Dialog>

    )
}