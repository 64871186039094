import { useMemo } from "react";
import BadgePill from "./BadgePill";
import Link from "next/link";
import { Building2, Eye } from "lucide-react";

const B2bPill = ({ contact }: { contact: any }) => {

    const companyGroups = useMemo(() => {
        return contact?.affiliatedCompanyGroup?.map((company) => {
            const { companyGroup } = company || {};
            if (!companyGroup) return null;
            const { businessName, _id } = companyGroup || {};
            if (!businessName || !_id) return null;
            return {
                companyName: businessName,
                companyGroupId: _id,
                role: company.role
            }
        }).filter((companyGroup) => companyGroup)
    }, [contact])

    if (!companyGroups || !companyGroups.length) return null

    return (
        companyGroups.map((companyGroup) => (
            <Link href={`/companies/${companyGroup.companyGroupId}`} key={companyGroup.companyGroupId}>
                <BadgePill className="cursor-pointer group">
                    <div className="flex flex-row items-center gap-1">
                        <Building2 size={12} className="group-hover:hidden block" />
                        <Eye size={12} className="group-hover:block hidden" />
                        <p>{companyGroup.companyName} {companyGroup.role ? `- ${companyGroup.role}` : ''}</p>
                    </div>
                </BadgePill>
            </Link>
        ))
    )
}

export default B2bPill