import { setTemplateReset } from "@/redux/slices/crm";
import "@/styles/scrollModify.css";
import { useSelector } from "react-redux";
import { ChatInputProvider } from "../ChatInput/Context";
import TemplateSelect from "../ChatInput/TemplateSelect";
import ButtonMoveInputHeight from "./ButtonMoveInputHeight";
import { ChatInputHandler } from "./ChatInputHandler";
import SelectAction from "./SelectAction";

export function BottomActions({
    setOpenUpdateContact,
    action,
    activeChannel,
    dispatch,
    downChatFc,
    handelActionPress,
    id,
    prospect,
    sendMessage,
    sendMultimedia,
    sendMultimediaPersonalWPP,
    isFromSharedInbox = false,
    setAction
}) {
    const isActiveTemplateMode = useSelector((state: any) => state.crm.isActiveTemplateMode);
    const { height: inputHeight } = useSelector((state: any) => state.crm.inputProperties);

    return (
        <ChatInputProvider>
            {isActiveTemplateMode && (
                <div className="bg-white m-3 rounded-lg border-2">
                    <TemplateSelect
                        send={sendMessage}
                        type="fastTemplatFromBar"
                        goBack={() => dispatch(setTemplateReset())}
                        channel={
                            action === 'facebook' ? { name: "Facebook", type: 'fbk' }
                                : action === 'instagram' ? { name: "Instagram", type: 'ig' }
                                    : action === 'whatsapp' ? { name: "Whatsapp", type: 'wpp' }
                                        : activeChannel
                        }
                    />
                </div>
            )}
            <div className=" mt-0 mx-3 mb-3  flex flex-col overflow-hidden  rounded-xl border-blue-100  rela z-10" style={{ boxShadow: '0px 0px 8px rgba(0,0,0,0.25)' }}>
                <div className={`justify-start group flex w-full h-[50px] bg-white relative overflow-x-auto`}>
                    <SelectAction
                        contactId={id}
                        isFromSharedInbox={isFromSharedInbox}
                        setOpenUpdateContact={setOpenUpdateContact}
                        onAction={handelActionPress}
                        action={action}
                    />
                    <div className="absolute opacity-0 group-hover:opacity-100 right-4 top-[11px]">
                        <ButtonMoveInputHeight />
                    </div>
                </div>
                <div
                    style={{ height: `${inputHeight}px` }}
                    className={`w-full bg-crm flex  justify-center flex-grow items-center overflow-y-auto`}>
                    <ChatInputHandler key={action} action={action} activeChannel={activeChannel} dispatch={dispatch} downChatFc={downChatFc} handelActionPress={handelActionPress} id={id} prospect={prospect} sendMessage={sendMessage} sendMultimedia={sendMultimedia} sendMultimediaPersonalWPP={sendMultimediaPersonalWPP} setAction={setAction} />
                </div>
            </div >
        </ChatInputProvider>
    )
}

