import { LoadingButton } from "@/components/buttons/loading-button"
import { Button } from "@/components/ui/button"
import SimpleTooltip from "@/components/ui/simple-tooltip"
import { generateRandomId } from "@/helpers/generateRandomId"
import { useToggle } from "@/hooks/useToggle"
import { cn } from "@/lib/utils"
import AWS from "aws-sdk"
import { CalendarClock, Files, Paperclip, Send, Trash2 } from "lucide-react"
import { ChangeEvent, ReactNode, useCallback, useRef } from "react"
import ScheduledModal from "../ChatInput/ScheduledModal"

AWS.config.update({
    accessKeyId: process.env.AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY,
});

const S3 = new AWS.S3();

export default function Footer({
    onOpenTemplateSelected,
    onChangeScheduleDate,
    onHandleSaveFile,
    emailTemplates,
    scheduleDate,
    handleClose,
    isLoading,
    onDrop,
    user
}) {
    const [openScheduleModal, toggleScheduleModal] = useToggle<boolean>();
    const inputFileRef = useRef<HTMLInputElement | null>(null);

    const handleActionForFile = () => {
        const inputElement = inputFileRef.current
        if (inputElement) inputElement.click()
    }

    const postFiles = useCallback(async (files: FileList | null) => {
        if (!files) return;

        const attachments = Array.from(files).map(async (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onload = async function () {
                    const arrayBuffer = this.result as ArrayBuffer;
                    const buffer = Buffer.from(arrayBuffer);
                    const filename = file.name;
                    const content_type = file.type;

                    try {
                        const key = 'attachments-' + user._id.toString() + '/' + new Date().getTime() + '/' + filename
                        const params = {
                            Bucket: 'ventia-s3-facebook',
                            Key: key,
                            Body: buffer,
                            ContentType: content_type
                        };

                        S3.upload(params, (err, data) => {
                            if (err) {
                                console.log('Hubo un problema al subir la imagen al servidor', err);
                                reject(err);
                            } else {
                                resolve({
                                    ...data,
                                    content_type,
                                    filename,
                                    size: file.size,
                                    id: generateRandomId()
                                });
                            }
                        });
                    } catch (err) {
                        reject(err);
                    }
                };

                reader.onerror = function () {
                    reject(new Error('Failed to read file'));
                };
                reader.readAsArrayBuffer(file);
            });
        });

        try {
            const results = await Promise.all(attachments);
            return results.map((result: any) => ({
                imageUrl: result.Location,
                key: result.Key,
                filename: result.filename,
                size: result.size,
                content_type: result.content_type
            }))

        } catch (err) {
            console.error(err);
        }
    }, [user._id]);

    const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files
        if (files) onDrop([...files as any])
        postFiles(files)
            .then(res => {
                if (res) onHandleSaveFile(res)
            }).catch(err => console.log(err))
    }

    return (
        <>
            <ScheduledModal
                open={!!openScheduleModal}
                onSuccess={onChangeScheduleDate}
                onOpenChange={toggleScheduleModal}
            />

            <footer className="w-full border-t  border-slate-200  flex py-[12px] relative z-[50] items-center justify-end px-5 ">
                <div className="flex items-center gap-2">
                    <input type="file" hidden ref={inputFileRef} multiple={false} onChange={handleChangeFile} />

                    <ActionButton
                        className={scheduleDate ? 'text-primary' : ''}
                        icon={<CalendarClock size={17} />}
                        onClick={toggleScheduleModal}
                        tooltip="Programar"
                    />

                    <ActionButton
                        icon={<Paperclip size={17} />}
                        onClick={handleActionForFile}
                        tooltip="Adjuntar archivo"
                    />

                    {
                        emailTemplates.length > 0 && (
                            <ActionButton
                                onClick={() => onOpenTemplateSelected(true)}
                                icon={<Files size={17} />}
                                tooltip="Respuestas rápidas"
                            />
                        )
                    }

                    <Button
                        className="flex items-center w-[34px] md:w-auto gap-2 text-xs"
                        onClick={handleClose}
                        variant={'outline'}
                        type="button"
                        disabled={isLoading}
                    >
                        <Trash2 size={15} className="shrink-0" />
                        <span className="md:block hidden">
                            Descartar
                        </span>
                    </Button>

                    <LoadingButton
                        className="text-xs"
                        form="formOfSendEmail"
                        loading={isLoading}
                        type="submit"
                    >
                        <span className="border-r border-white pr-2">
                            {scheduleDate ? 'Programar' : 'Enviar ahora'}
                        </span>
                        <span className="inline ml-2">
                            {
                                !scheduleDate
                                    ? <Send size={15} />
                                    : <CalendarClock size={15} />
                            }
                        </span>
                    </LoadingButton>
                </div>
            </footer>
        </>

    )
}

interface ActionButtonProps {
    onClick: () => void,
    className?: string,
    tooltip: string,
    icon: ReactNode
}

export function ActionButton(props: ActionButtonProps) {
    return (
        <SimpleTooltip
            className="z-[999999]"
            content={<p>{props.tooltip}</p>}
        >
            <Button
                size={'icon'}
                type="button"
                variant={'outline'}
                onClick={props.onClick}
                className={cn('w-[34px] hover:bg-slate-200 h-[34px]', props.className)}
            >
                {props.icon}
            </Button>
        </SimpleTooltip>
    )
}