import { getCompany, isAuth } from '@/actions/auth'
import { archive, cancelreminder } from '@/actions/crm/contact'
import { Collapse } from '@mui/material'
import _ from 'lodash'
import mixpanel from 'mixpanel-browser'
import { useMemo } from 'react'
import ArchivePill from './Pills/ArchivePill'
import CountUnassignedPill from './Pills/CountUnassignedPill'
import FiltersPill from './Pills/FiltersPill'
import ReminderPill from './Pills/ReminderPill'
import StatusTagPill from './Pills/StatusTagPill'
import SubConversationPill from './Pills/SubConversationPill'
import BotStatusPill from './Pills/BotStatusPill'
import { Feature, isFeatureEnabled } from '@/helpers/featureFlagHelpers'
import B2bPill from './Pills/B2bPill'
import AdditionalDataPill from './Pills/AdditionalDataPill'

export default function ProspectCollapseInformation({
  contact,
  mode,
  countUnassigned,
  handleAfkError
}) {
  const { statusTag, reminder, archivingReason, agent, subConversation, lockedBy, _id: contactId, remiderDetail, botConfig } = contact || {}
  const groupTags = useMemo(() => _.get(getCompany(), 'statusTags', []), [])
  const userId = useMemo(() => _.get(isAuth(), '_id', ''), [])
  const isBotActive = useMemo(() => (botConfig ?? []).filter(bot => bot?.isActive).length > 0, [botConfig])
  const groupAdditionalFieldsOnHeader = useMemo(() => {
    const allFields = _.get(getCompany(), 'additionalDataFields', [])
    const showOnProspectHeaderFields = allFields.filter(field => field.showOnProspectHeader && field.type === 'select' && field.active)
    return showOnProspectHeaderFields
  }, [])

  const showUnderChips = useMemo(() => {
    if (!contact) return false
    if (
      archivingReason
      || reminder
      || (mode === 'unassigned' && countUnassigned)
      || (statusTag || groupTags?.length > 0)
      || (agent && agent != userId)) return true
        || !!subConversation?.contact
    if (lockedBy) return true
    return false
  }, [contact, countUnassigned, mode])

  return (
    <Collapse
      in={showUnderChips}
      style={{ background: "none", height: "0px" }}
    >
      <div className="px-4 max-w-[100%] relative py-[6px] z-[2]">
        <div className="flex flex-wrap gap-1 max-w-full w-full z-5">
          {
            mode === 'unassigned' && handleAfkError && <CountUnassignedPill handleAfkError={handleAfkError} />
          }
          <ArchivePill
            contactId={contactId}
            reason={archivingReason}
          />
          <ReminderPill
            contactId={contactId}
            reminder={{ reminder, ...remiderDetail }}
          />
          <StatusTagPill
            statusTag={statusTag}
            id={contactId}
          />
          {groupAdditionalFieldsOnHeader.length > 0 && groupAdditionalFieldsOnHeader.map((field, index) => {
            const contactCurrentFieldValue = contact?.additionalData[field.code]
            return <AdditionalDataPill contactId={contactId} field={field} key={index} currentValue={contactCurrentFieldValue} />
          })
          }
          <BotStatusPill
            isActive={isBotActive}
          />
          <B2bPill contact={contact} />
          <SubConversationPill prospect={contact} />
          {
            showUnderChips && <span
              className="absolute w-full h-[50px] left-0 top-0"
              style={{
                background: "linear-gradient(0deg, transparent, #fff 100%)",
              }}
            />
          }
          <FiltersPill />
        </div>
      </div>
    </Collapse>
  )
}
