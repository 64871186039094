import { fetcher } from '@/helpers/fetcher';
import { formatSearchParams } from '@/helpers/formatSearchParams';
import { ICompanyGroup, ICompanySector } from '@/interfaces/Interface';
import fetch from 'isomorphic-fetch';
import { getCookie } from '../auth';
import { DefualtFieldSchemaType } from '@/components/comercial/additional-field/EditDefaultField';

const API = process.env.API
export type ContactSearchParams = Record<string, number | string>

export interface BodyForAddContacts {
    contacts: string[],
    role?: string,
    sector?: string
}

export const createCompanyGroup = (data) => {
    return fetcher<ICompanyGroup[]>({
        url: `/comercial/companyGroup`,
        defaultContentType: true,
        method: 'POST',
        body: data
    })
}

export const getCompanyGroupContacts = (id: string, params: ContactSearchParams = {}) => {
    const searchParams = formatSearchParams(params)
    return fetcher<{ contacts: Array<any>, countDocuments: number }>({
        url: `/comercial/companyGroup/${id}/contacts?${searchParams}`,
        defaultContentType: true,
        method: 'GET'
    })
}

export const getCompanyGroupById = (id: string) => {
    return fetcher<ICompanyGroup>({
        url: `/comercial/companyGroup/${id}`,
        defaultContentType: true,
        method: 'GET'
    })
}

export const getCompanyGroup = () => {
    return fetch(`${API}/table/companyGroup`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'JWT ' + getCookie('token')
        },
    })
        .then(response => {
            return response.json()
        })
        .catch(err => console.log(err))
}

export const updateCompanyGroup = (data: Record<string, any>, companyGroupId: string) => {
    return fetcher({
        url: `/comercial/companyGroup/${companyGroupId}`,
        defaultContentType: true,
        method: 'PATCH',
        body: data
    })
}

export const getCompanySectors = (id: string, params: ContactSearchParams = {}) => {
    const searchParams = formatSearchParams(params)
    return fetcher<{
        sectors: ICompanySector[],
        countDocuments: number
    }>({
        url: `/comercial/companyGroup/${id}/sectors?${searchParams}`,
        defaultContentType: true,
        method: 'GET'
    })
}

export const createCompanySector = (id: string, body: {
    name: string
}) => {

    return fetcher({
        url: `/comercial/companyGroup/${id}/sectors`,
        defaultContentType: true,
        throwError: false,
        method: 'POST',
        body
    })
}

export const deleteCompanySectorById = (company: string, sectorid: string) => {
    return fetcher<{ success: boolean }>({
        url: `/comercial/companyGroup/${company}/sectors/${sectorid}`,
        defaultContentType: true,
        method: 'DELETE'
    })
}

export const addContactsToCompanyGroup = (companyId: string, body: BodyForAddContacts) => {
    return fetcher({
        url: `/comercial/companyGroup/${companyId}/addContacts`,
        defaultContentType: true,
        method: 'POST',
        body
    })
}

export const getCompanyGroupList = (params: Record<string, any>) => {
    let queryParams: any = formatSearchParams(params, { deniedValue: ['all'] })

    return fetcher<{ companyGroups: any[], countDocuments: number }>({
        url: `/crm/companygroups?${queryParams}`,
        defaultContentType: true,
        throwError: false
    })
}

export const updateDefaultFieldFromCompany = (id: string, field: DefualtFieldSchemaType) => {
    return fetcher({
        url: `/group/${id}/companyfield/defaultField/modify`,
        defaultContentType: true,
        method: 'PATCH',
        body: field
    })
}