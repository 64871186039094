import { Button } from "@/components/ui/button";
import { Command, CommandEmpty, CommandGroup, CommandItem } from "@/components/ui/command";
import { Input } from '@/components/ui/input';
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { Collapse } from '@mui/material';
import { Check, ChevronsUpDown, Loader2 } from "lucide-react";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { getCompany } from '../../../../actions/auth';
import { maxText } from '../../TextInteraction';
import transformAddress from './transformAddress';
import { useRouter } from "next/router";

const YOUR_GOOGLE_MAPS_API_KEY = "AIzaSyCcLiFC3dlK1XRTvm_1guQSPc5c2XKRqbQ"
interface AddressInputProps {
  askExtraInfo: boolean,
  onAddressChange: (address?: any) => void,
  address: any,
  disabled?: boolean,
  required: boolean,
  placeholder?: string
}


function AddressInputShadcn({
  askExtraInfo,
  onAddressChange,
  address,
  required,
  disabled,
  placeholder
}: AddressInputProps) {
  const [location, setLocation] = useState<any>();
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState("")

  const router = useRouter()

  useEffect(() => {
    address && setValue(address.formatted_address)
  }, [address])

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    sessionToken: true,
    debounce: 500,
    language: 'es',
    options: {},
    apiKey: YOUR_GOOGLE_MAPS_API_KEY
  });

  const handleChange = useCallback((value) => {
    if (value) {
      placesService?.getDetails({ placeId: value.place_id },
        (placeDetails) => {
          onAddressChange(transformAddress(placeDetails))
        }
      );
    } else onAddressChange()
  }, [onAddressChange, placesService])

  useEffect(() => {
    if (navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLocation({ latitude: position.coords.latitude, longitude: position.coords.longitude })
      });
    }
  }, [navigator])

  const generatePrediction = useCallback((text: string) => {
    getPlacePredictions({
      input: text,
      types: ["address"],
      lenguage: 'es',
      // componentRestrictions: { country: getCompany().country || 'uy' },
      bounds: (location && location.latitude) ? {
        north: location.latitude + 0.1,
        south: location.latitude - 0.1,
        east: location.longitude + 0.1,
        west: location.longitude - 0.1,
      } : undefined
    })
  }, [getPlacePredictions, location, getCompany])

  const predictions = useMemo(() => {
    if (placePredictions) {
      let result = placePredictions.map((prediction) => ({
        formatted_address: prediction.description,
        place_id: prediction.place_id,
        prediction
      }));
      return result
    }
    return []
  }, [placePredictions])

  const onViewInMap = () => {
    router.push({
      pathname: `https://www.google.com/maps/search`,
      query: {
        api: 1,
        query: address?.formatted_address,
        query_place_id: address?.place_id
      }
    })
  }

  return (
    <>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            disabled={disabled}
            role="combobox"
            aria-expanded={open}
            className={`w-full font-normal h-10 justify-between ${disabled ? 'cursor-not-allowed' : ''}`}
          >
            {value
              ? maxText(value, 20)
              : placeholder}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="sm:w-[320px] w-[220px] p-0">
          <Command>
            <Input className='border-none focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0' placeholder="Busca una dirección..." onChange={(e) => generatePrediction(e.target.value)} />
            {isPlacePredictionsLoading
              ? <div className='w-full flex justify-center items-center pb-4 text-primary'>
                <Loader2 className="h-4 w-4 animate-spin" />
              </div>
              : <CommandEmpty>Dirección no encontrada.</CommandEmpty>}
            <CommandGroup className='py-0'>
              {predictions.map((framework) => {
                const matches = framework.prediction.structured_formatting.main_text_matched_substrings;
                let results: any = []
                let string = framework.formatted_address

                let lastOffset = 0

                for (let i = 0; i < matches.length; i++) {
                  if (matches[i].offset > lastOffset) {
                    results.push({ str: string.substring(lastOffset, matches[i].offset), highlight: false })
                  }
                  results.push({ str: string.substring(matches[i].offset, matches[i].offset + matches[i].length), highlight: true })
                  lastOffset = matches[i].offset + matches[i].length
                }
                if (lastOffset < string.length) {
                  results.push({ str: string.substring(lastOffset, string.length), highlight: false })
                }

                return (
                  <CommandItem key={framework.place_id} onSelect={() => {
                    setValue(framework.formatted_address)
                    handleChange(framework)
                    setOpen(false)
                  }}>
                    <div className='flex items-center'>
                      <Check
                        className={cn(
                          "mr-2 h-4 w-4",
                          value === framework.formatted_address ? "opacity-100" : "opacity-0"
                        )}
                      />
                      <div>
                        {results.map((part: any, index) => (
                          <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}
                          >
                            {part.str}
                          </span>
                        ))}
                      </div>
                    </div>
                  </CommandItem>
                );

              })}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>

      <Collapse in={askExtraInfo && address && address.location}>
        <div className='mt-2'>
          <Input
            placeholder="Nota dirección"
            value={address?.extraInfo}
            className='w-full'
            onChange={(e) => onAddressChange({ ...address, extraInfo: e.target.value })}
          />
        </div>

        <div className='pt-2 w-full'>
          <Button
            type="button"
            variant={'link'}
            className="w-full"
            onClick={onViewInMap}
          >
            Ver en mapa
          </Button>
        </div>
      </Collapse>

    </>
  )
}

export default AddressInputShadcn;