import {
    Tooltip
} from "@mui/material";
import { AccordionTrigger } from "@radix-ui/react-accordion";
import _ from "lodash";
import { Badge, ChevronDown, ChevronUp, Loader2, Users } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import {
    FaUserAlt,
    FaUserFriends,
    FaUserGraduate,
    FaUserTag,
    FaUsers,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
    setMenuOpen,
    setSelectedGroup,
    setSelectedUser,
} from "../../redux/actions/main";
import { Accordion, AccordionContent, AccordionItem } from "../ui/accordion";
import { Avatar } from "../ui/avatar";
import { Button } from "../ui/button";
import Link from "next/link";
import { useRouter } from "next/router";

const userIcon = {
    undefined: <FaUserAlt size={18} />,
    normal: <FaUserAlt size={18} />,
    a_prueba: <FaUserGraduate size={18} />,
    independiente: <FaUserTag size={18} />,
};

const ItemUser = ({ children, user, active = false, icon, group = null }: any) => {
    const router = useRouter()
    const dispatch = useDispatch();

    const handleSelectedUser = () => {
        dispatch(setMenuOpen(false));

        const query = {}

        if (user) {
            query['view-user'] = user._id
        } else if (group) {
            query['view-group'] = group._id
        }

        router.push({
            pathname: '/',
            query,
        })
    }

    return (
        <Tooltip title={children} placement="right">
            <div className="p-1">
                <Button className="w-[100%] border-0 hover:bg-blue-100 p-2"
                    size="default"
                    variant={'outline'}
                    style={{
                        fontSize: "14px",
                        justifyContent: "flex-start",
                        textTransform: "none",
                        color: user && user.color && !active ? user.color : active ? "#0080ff" : "#000000",
                        borderRadius: "10px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap"
                    }}
                    onClick={handleSelectedUser}
                >
                    <div className="flex items-center justify-start">
                        {user ? <Avatar className={`flex items-center justify-center h-6 w-6 text-white `} style={{ backgroundColor: user.color ? user.color : '#0080FF' }}>
                            {user && user.photo && user.photo.length > 0 ? <img src={user.photo} className='w-full h-full object-cover' alt="User profile" /> : <p className="text-[12px]">{user?.name?.[0].toUpperCase()}{user?.lastname?.[0]?.toUpperCase()}</p>}
                        </Avatar> : <Users size={18} />}
                        <p className="whitespace-nowrap text-left ml-3 text-sm">
                            {children}
                        </p>
                        {user &&
                            user.userType &&
                            (user.userType == "a_prueba" ||
                                user.userType == "independiente") && (
                                <Badge className="text-[10px] h-[20px] p-0 " style={{ borderRadius: '5px' }}>
                                    {user.userType == "a_prueba" ? "A prueba" : "Subagente"}
                                </Badge>
                            )}
                    </div>
                </Button>
            </div >
        </Tooltip >
    );
};

const generateUsersGroup = (group) => {
    if (group.group) {
        let user_group = [];
        group.group.map((grp) => {
            user_group = [...user_group, ...generateUsersGroup(grp)];
        });
        return [...user_group, ...group.users];
    }
    return group.users;
};

const generateGroup = (group, selectedUser, selectedGroup, level) => {
    let users =
        group.users &&
        group.users.filter((u) => u.active && u.roles.includes("user"));

    let extra_users = generateUsersGroup(group);

    return (
        <ItemGroup
            key={group._id}
            active={
                (selectedUser &&
                    group.users.filter((u) => selectedUser._id == u._id)
                        .length > 0) ||
                (selectedGroup && selectedGroup._id == group._id)
            }
            icon={<FaUsers size={22} />}
            defaultExpanded={level == 0}
            group={group}
        >
            <div style={{ paddingLeft: level * 4 + "px" }}>
                <ItemUser
                    active={selectedGroup && selectedGroup._id == group._id}
                    icon={<FaUserFriends size={22} />}
                    user={null}
                    group={{ ...group, users: extra_users }}
                >
                    Todo el grupo ({extra_users.filter(u => u.active && u.roles.includes('user')).length})
                </ItemUser>
                {group.group.map((grp) =>
                    generateGroup(grp, selectedUser, selectedGroup, level + 1)
                )}
                {users
                    .sort((a, b) =>
                        `${a.name} ${a.lastname}`.localeCompare(
                            `${b.name} ${b.lastname}`
                        )
                    )
                    .map((u) => (
                        <ItemUser
                            key={u._id}
                            user={{ ...u, group }}
                            active={selectedUser && selectedUser._id == u._id}
                            icon={userIcon[u.userType]}
                        >
                            {u.name} {u.lastname}
                        </ItemUser>
                    ))}
            </div>
        </ItemGroup>
    );
};

const ItemGroup = ({
    children,
    group,
    active = false,
    icon,
    defaultExpanded = false,
}) => {
    let [expanded, setExpaneded] = useState(defaultExpanded);

    // let handleExpand = (_, change) => {
    //     console.log(first)
    //     setExpaneded(!expanded);
    // };

    const handleExpand = () => {
        console.log('click')
        setExpaneded(!expanded);
    };

    const color = useMemo(() => {
        let sum = 0, count = 0;
        let listUsers = [...group.users, ...group.group.map((g) => generateUsersGroup(g)).flat()]

        listUsers.map(u => {
            if (u.color == "#ED6C02") {
                sum += 1
            } else if (u.color == "#2e7d32") {
                sum += 2
            }
            if (u.color) count++
        })

        if (count * 1.5 < sum) {
            return "#2e7d32"
        } else if (count * 1 < sum) {
            return "#ED6C02"
        } else if (count == 0) {
            return "#000"
        } else {
            return "#d32f2f"
        }

    }, [group])
    return (
        // <ThemeProvider theme={theme}>
        <Accordion
            type="multiple"
            orientation="vertical"
            // onChange={handleExpand}
            className="w-full m-0 p-0"
        >
            <AccordionItem value={group.name} className="border-0">
                <AccordionTrigger onClick={handleExpand} className="w-full">
                    <Button className="w-full border-0 hover:bg-blue-100 p-2 flex justify-between"
                        size="default"
                        variant={'outline'}
                        style={{
                            fontSize: "14px",
                            textTransform: "none",
                            color: active ? "#0080ff" : "#000",
                            borderRadius: "10px",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            height: "42.25px",
                            maxWidth: "248px",
                            margin: 0,
                        }}
                        onClick={() => {
                            // dispatch(setSelectedUser(user))
                            // dispatch(setMenuOpen(false))
                        }}>
                        <div>
                            <p className="whitespace-nowrap text-left ml-3 text-sm">
                                {group.name}
                            </p>
                        </div>
                        <div>
                            {expanded ? <ChevronDown size={18} /> : <ChevronUp size={18} />}
                        </div>
                    </Button>
                </AccordionTrigger>
                <AccordionContent className="w-full">
                    {children}
                </AccordionContent>
            </AccordionItem>
        </Accordion >
        // </ThemeProvider >
    );
};

const UserList = ({ groups = [], loading, search }) => {
    const router = useRouter()
    var selectedUser = useSelector((state: any) => state.main.selected_user);
    var selectedGroup = useSelector((state: any) => state.main.selected_group);

    const dispatch = useDispatch()

    const groupWithUsers = useMemo(() => {
        if (groups && Array.isArray(groups)) {

            return groups?.filter((group) => group.users.length > 0)
        }
        return []
    }, [groups])

    const baseUsersList = useMemo(() => {
        if (groupWithUsers.length == 0) return [];
        if (groupWithUsers.length == 1) {
            return groupWithUsers[0]
                .users.filter((u) => u.active && u.roles.includes("user"))
                .sort((a, b) =>
                    `${a.name} ${a.lastname}`.localeCompare(
                        `${b.name} ${b.lastname}`
                    )
                );
        }
        let usersTemp = groupWithUsers.map((g) => g.users).flat()
        return usersTemp.filter((u) => u.active && u.roles.includes("user"))
            .sort((a, b) =>
                `${a.name} ${a.lastname}`.localeCompare(
                    `${b.name} ${b.lastname}`
                )
            );
    }, [groupWithUsers])

    const usersList = useMemo(() => {
        if (search == "" || !search) return baseUsersList
        return baseUsersList.filter((u) => {
            let normailzedSearch = search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            return (
                `${u.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")} ${u.lastname.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")}`.includes(normailzedSearch)
                || u.email.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(normailzedSearch)
            );
        }
        )

    }, [baseUsersList, search])

    const userInDictionary: Record<string, any> = useMemo(() => { // Improve User Search Performance
        if (baseUsersList.length == 0) return {}
        const listOfUser = {}

        baseUsersList.forEach((user) => listOfUser[user._id] = user)

        return listOfUser
    }, [baseUsersList])

    const groupInDictionary: Record<string, any> = useMemo(() => { // Improve Group Search Performance
        if (groupWithUsers.length == 0) return {}
        const listOfUser = {}

        groupWithUsers.forEach((group: any) => listOfUser[group._id] = getGroupList(group))

        return listOfUser
    }, [groupWithUsers])

    useEffect(() => {
        const query = router.query
        const queryUser = query['view-user']
        const queryGroup = query['view-group']

        if (!!queryUser) {
            const user = userInDictionary[queryUser as string]
            if (user) dispatch(setSelectedUser(user))
        } else {
            dispatch(setSelectedUser(null))
        }

        if (!!queryGroup) {
            const group = groupInDictionary[queryGroup as string]
            if (group) {
                dispatch(setSelectedGroup({ ...group, users: generateUsersGroup(group) }));
            }
        } else {
            dispatch(setSelectedGroup(null))
        }

        dispatch(setMenuOpen(false));
    }, [router.query['view-user'], router.query['view-group'], groupInDictionary, userInDictionary, router])

    if (loading) {
        return (
            <div className="flex justify-center w-full mt-[5px]">
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            </div>
        );
    }

    if (groupWithUsers.length == 0) return "";

    if (groupWithUsers.length == 1 || (search !== "" && search)) {

        return (
            <>
                {(search !== "" && search) ?
                    <></>
                    : <ItemUser
                        active={!selectedUser}
                        icon={<FaUserFriends size={22} />}
                        user={null}
                    >
                        Todos ({usersList ? usersList.length : ""})
                    </ItemUser>
                }
                {usersList &&
                    usersList.map((u) => (
                        <ItemUser
                            key={u._id}
                            user={{ ...u, group: groups[0] }}
                            active={selectedUser && selectedUser._id == u._id}
                            icon={userIcon[u.userType]}
                        >
                            {u.name} {u.lastname}
                        </ItemUser>
                    ))}
            </>
        );
    }

    let minLevel;
    groups.map((g) => {
        let level = g.ancestors.length;
        if (minLevel == undefined || minLevel > level) {
            minLevel = level;
        }
    });
    function getGroupList(g) {
        let group_find = groups.filter(
            (grp) =>
                grp.ancestors.length - 1 >= 0 &&
                grp.ancestors[grp.ancestors.length - 1] == g._id
                && _.get(grp, 'active', true)
        );

        return { ...g, group: group_find.map((grp) => getGroupList(grp)) };
    };
    let newGroupStruct = groups.filter((g) => g.ancestors.length == minLevel);
    newGroupStruct = newGroupStruct.map((g) => getGroupList(g));

    return (
        <>
            <ItemUser
                active={!selectedUser && !selectedGroup}
                icon={<FaUsers size={22} />}
                user={null}
            >
                Toda la empresa ({groups.reduce((a, b) => a + b.users.filter((u) => u.active && u.roles.includes('user')).length, 0)})
            </ItemUser>
            <div className="m-0 p-0">
                {newGroupStruct
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .filter((g) => _.get(g, 'active', true))
                    .map((group) => {
                        let users =
                            group.users &&
                            group.users.filter(
                                (u) => u.active && u.roles.includes("user")
                            );

                        return (
                            <>
                                {group.group.map((grp) =>
                                    <div key={grp._id}>
                                        {
                                            generateGroup(
                                                grp,
                                                selectedUser,
                                                selectedGroup,
                                                1
                                            )
                                        }
                                    </div>
                                )}
                                {users
                                    .sort((a, b) =>
                                        `${a.name} ${a.lastname}`.localeCompare(
                                            `${b.name} ${b.lastname}`
                                        )
                                    )
                                    .map((u) => (
                                        <ItemUser
                                            key={u._id}
                                            user={{ ...u, group }}
                                            active={
                                                selectedUser &&
                                                selectedUser._id == u._id
                                            }
                                            icon={userIcon[u.userType]}
                                        >
                                            {u.name} {u.lastname}
                                        </ItemUser>
                                    ))}
                            </>
                        );
                    })}
            </div>
        </>
    );
};

export default UserList;
