
import { getCompany } from '@/actions/auth'
import _ from 'lodash'

import { useMemo } from 'react'

export default function useGetArchivingReason({ code }: { code?: string }) {

    
    const [reasons, allReasons] = useMemo(() => {
        let reasonsList = _.get(getCompany(), 'archivingReasons', [])
        let reasons = reasonsList.filter(reason => reason.active)
        return [reasons, reasonsList]
    }, [])

    if(!code || code == '') return {reasons, reason: {}}


    const find = allReasons.find(tag => tag.code === code) || {
        name: code,
        code: code
    }

    return { reasons, reason: find }
}
