import { getContactsList } from "@/actions/contacts";
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from "react";

export interface IPreviewContact {
    firstName: string,
    origin: string,
    lastInteraction: string,
    statusTag: string,
    lastName?: string
    _id: string
}

export default function useGetContacts({
    externalSearchParams,
    enabled = true,
    queryKey = ['get-contacts-list']
}: {
    externalSearchParams?: Record<string, any>,
    enabled?: boolean,
    queryKey?: string[]
} = {}) {
    const [contacts, setContacts] = useState<Array<any>>([])
    const [countDocuments, setCountDocuments] = useState(0)
    const [hasMore, setHasMore] = useState<boolean>(true)
    const [searchParams, setParams] = useState<{ [key: string]: any }>({ page: 1, limit: 50 });

    const searchParamUse = externalSearchParams || searchParams

    const { data, isLoading, ...rest } = useQuery({
        queryKey: [...queryKey, searchParamUse],
        queryFn: () => getContactsList({ ...searchParamUse }),
        enabled
    });

    useEffect(() => {
        const { countDocuments, contacts: newContactsList } = data || {}
        if (countDocuments) setCountDocuments(countDocuments)

        if (newContactsList) {
            setContacts(lastValue => searchParamUse.page == 1
                ? newContactsList
                : [...lastValue, ...newContactsList]
            );

            setHasMore(newContactsList.length >= searchParamUse.limit);
        }
    }, [data]);

    const onChangeFilters = (obj: { [key: string]: string }) => {
        setParams(lastValue => ({ ...lastValue, ...obj, page: 1 }))
    };

    const onChangePage = (page: number) => {
        setParams(lastValue => ({ ...lastValue, page }));
    };

    const isLoadingFirstPage = searchParamUse.page === 1 && isLoading;

    return {
        contacts,
        countDocuments,
        isLoadingFirstPage,
        onChangeFilters,
        onChangePage,
        isLoading,
        hasMore,
        data,
        searchParams,
        ...rest
    }
}