import { isAuth } from "@/actions/auth";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Separator } from "@/components/ui/separator";
import { replaceObjectValues } from "@/helpers/replaceObjectValues";
import { ArrowLeft, Check, Files, SendHorizontal } from "lucide-react";
import { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";

interface EmailInputProps {
    send: (template: Record<string, any>) => void;
    goBack: () => void;
    templates?: any,
}

const EmailTemplateSelect = ({
    send,
    goBack,
    templates,
}: EmailInputProps) => {
    const [searchText, setSearchText] = useState("");
    const [selectedTemplate, setSelectedTemplate] = useState<any>();
    const prospect = useSelector((state: any) => state.crm.contact);

    const replaceTemplateVariables = (message: string) => {
        const { name, lastname, phone_work, _id: userId, phone, email } = isAuth() || {}
        const { _id: companyId, displayName, country } = isAuth().group || {}

        const variables = {
            contact: prospect,
            group: {
                _id: companyId,
                displayName,
                country
            },
            user: {
                name,
                lastname,
                phone_work,
                _id: userId,
                phone,
                email
            }
        }
        return replaceObjectValues(message, variables)
    }

    const handleSend = (e) => {
        e.preventDefault();
        const message = replaceTemplateVariables(selectedTemplate.message);
        const formattedTemplate = {
            ...selectedTemplate,
            message,
        }
        send(formattedTemplate);
    }

    const handleGoBack = useCallback(
        (e) => {
            e.preventDefault();
            setSelectedTemplate(undefined);
            goBack();
        },
        [goBack, setSelectedTemplate]
    );


    const activeTemplate = useMemo(() => {
        return templates
    }, [templates, selectedTemplate])

    const handleSelectTemplate = useCallback((template) => {
        if (selectedTemplate && template._id === selectedTemplate._id) return setSelectedTemplate(undefined)
        setSelectedTemplate(template)
    }, [setSelectedTemplate, selectedTemplate])

    const filterTemplates = (template) => {
        const templateText = template.name || template.title || template.message;
        const titleMatches = (template.name || template.title)?.toLowerCase().includes(searchText.toLowerCase());
        const messageMatches = template.message?.toLowerCase().includes(searchText.toLowerCase());
        return titleMatches || messageMatches;
    };

    return (
        <>
            <form onSubmit={handleSend} className="w-full p-3  flex-grow">
                <div>
                    <div className="w-full flex items-center space-x-2 justify-between">
                        <div className="flex items-center gap-2">
                            <Button type="button" variant={'ghost'} size={'icon'} onClick={handleGoBack}>
                                <ArrowLeft size={18} />
                            </Button>
                            <span className="gap-1 flex items-center">
                                <Files size={16} />
                                <p className="font-medium text-[15px]">Tus respuestas rápidas</p>
                            </span>
                        </div>
                        <div>
                            <Button type="submit" variant={'ghost'} size={'icon'} disabled={!selectedTemplate}>
                                <SendHorizontal className="text-primary" />
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="p-2">
                    <Input
                        type="text"
                        placeholder="Buscar plantilla..."
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        className="mb-2 p-2 border rounded focus-visible:ring-0 bg-[#F5F5F5] text-[12px]"
                    />
                </div>
                <div className="w-auto flex flex-col max-h-[300px] overflow-y-auto mx-2 mb-2">
                    {templates?.filter(filterTemplates).map((template, index) => (
                        <>
                            {activeTemplate?.length > 0 && (
                                <Separator className="w-full" orientation="horizontal" />
                            )}
                            <div
                                key={template._id}
                                onClick={() => handleSelectTemplate(template)}
                                className="cursor-pointer flex items-center p-2 hover:bg-slate-50 w-full justify-between"
                            >
                                <div className="flex flex-col">
                                    <p className="text-[#6B7280] font-medium">{template.title}</p>
                                    <p className="text-[#6B7280] font-medium text-[14px]">Asunto: {template.subject}</p>
                                    <p className="text-black/70 text-[14px] font-normal">{template.summary}</p>
                                </div>
                                {template._id === selectedTemplate?._id && (
                                    <div>
                                        <Check size={18} />
                                    </div>
                                )}
                            </div>
                            {index !== templates.length - 1 && (
                                <Separator className="w-full" orientation="horizontal" />
                            )}
                        </>
                    ))}
                </div>
            </form>
        </>
    );
};

export default EmailTemplateSelect;
