import _ from "lodash"
import { getCompany, isAuth } from "../actions/auth"
import { StatusTag } from "@/interfaces/Interface"

export const contactStatusTagColorArray = ['red', 'orange', 'yellow', 'green', 'blue', 'purple', 'pink', 'lemon', 'tea', 'ocean', 'president', 'sea', 'blue-light', 'violet', 'violet-light', 'burgundy', 'burgundy-light', 'fury', 'fury-light', 'brown', 'brown-light', 'yellow-light'] as const
export const contactStatusTagTypeArray = ['new', 'contacted', 'offered', 'presale', 'sale', 'canceled'] as const

interface ColorPalette {
    primary: string,
    secondary: string,
    textES: string
}

export const tagColorToPalette: Record<typeof contactStatusTagColorArray[number] | '', ColorPalette> = {
    '': {
        primary: '#9e9e9e',
        secondary: '#f5f5f5',
        textES: 'Sin etiqueta'
    },
    red: {
        primary: '#f44336',
        secondary: '#ffebee',
        textES: 'Rojo'
    },
    orange: {
        primary: '#ff9800',
        secondary: '#fff3e0',
        textES: 'Naranja'
    },
    yellow: {
        primary: '#ffeb3b',
        secondary: '#fffde7',
        textES: 'Amarillo'
    },
    green: {
        primary: '#4caf50',
        secondary: '#e8f5e9',
        textES: 'Verde'
    },
    blue: {
        primary: '#2196f3',
        secondary: '#e3f2fd',
        textES: 'Azul'
    },
    purple: {
        primary: '#9c27b0',
        secondary: '#f3e5f5',
        textES: 'Morado'
    },
    pink: {
        primary: '#e91e63',
        secondary: '#fce4ec',
        textES: 'Rosa'
    },
    lemon: {
        primary: '#A6AF39',
        secondary: '#FAFAEB',
        textES: 'Citron'
    },
    tea: {
        primary: '#115E59',
        secondary: '#CCFBF1',
        textES: 'Te'
    },
    ocean: {
        primary: '#3730A3',
        secondary: '#E0E7FF',
        textES: 'Oceano'
    },
    president: {
        primary: '#075985',
        secondary: '#E0F2FE',
        textES: 'Presidente'
    },
    sea: {
        primary: '#DBEAFE',
        secondary: '#1E40AF',
        textES: 'Mar'
    },
    'blue-light': {
        primary: '#E0F2FE',
        secondary: '#075985',
        textES: 'Azul claro'
    },
    violet: {
        primary: '#6B21A8',
        secondary: '#F3E8FF',
        textES: 'Violeta'
    },
    'violet-light': {
        primary: '#F3E8FF',
        secondary: '#6B21A8',
        textES: 'Violeta claro'
    },
    burgundy: {
        primary: '#9D174D',
        secondary: '#FCE7F3',
        textES: 'Bordo'
    },
    'burgundy-light': {
        primary: '#FCE7F3',
        secondary: '#9D174D',
        textES: 'Bordo'
    },
    fury: {
        primary: '#991B1B',
        secondary: '#FEE2E2',
        textES: 'Furia'
    },
    'fury-light': {
        primary: '#FEE2E2',
        secondary: '#991B1B',
        textES: 'Furia claro'
    },
    brown: {
        primary: '#FEE2E2',
        secondary: '#A23A0A',
        textES: 'Marrón'
    },
    'brown-light': {
        primary: '#A23A0A',
        secondary: '#FEE2E2',
        textES: 'Marrón claro'
    },
    'yellow-light': {
        primary: '#FEF9C3',
        secondary: '#854D0E',
        textES: 'Amarillo claro'
    }
}

export const tagTypeToSpanish: Record<typeof contactStatusTagTypeArray[number] | '', string> = {
    new: 'Nuevo',
    contacted: 'Contactado',
    offered: 'Ofertado',
    presale: 'Pre-venta',
    sale: 'Venta',
    canceled: 'Cancelado',
    '': 'Sin etiqueta'
}

export const activeStatusTags: StatusTag[] = _.get(getCompany(), 'statusTags', []).filter(tag => tag.active) as StatusTag[]
export const tagsToMap = _.keyBy(activeStatusTags, 'code');

export const getStatusTagByCode = (code: string): (Omit<StatusTag, 'color'> & { color: string }) | null => {
    const tag = tagsToMap[code];
    if (!tag) return null

    const color = tagColorToPalette[tag.color]['primary'];

    return {
        ...tag,
        color
    }
};

export const getStatusTagColor = (tagNameCode: string, tagColorValue: keyof typeof tagColorToPalette["red"]) => {
    let companyTags = _.get(getCompany(), 'statusTags', [])
    let tag = companyTags.find((tag: any) => tag.code === tagNameCode)
    return tag ? tagColorToPalette[tag.color][tagColorValue] : tagColorToPalette[''][tagColorValue]
}

export const getFilteredStatusTags = () => {
    const tagsList = Array.from(activeStatusTags);

    const additionalUsersFields = _.get(getCompany(), 'additionalUserFields', [])
    const companyAdditionalDataToMap = _.keyBy(additionalUsersFields, 'key')
    const userAdditionalFields = _.get(isAuth(), 'additionalData', [])
    const userGroup = _.get(isAuth(), 'group', '')

    return tagsList.filter(({ usersFilters = [], groupFilters = [] }) => {
        if (groupFilters && groupFilters.length > 0) {
            if (!groupFilters.includes(userGroup._id)) return false
        }

        if (usersFilters.length == 0) return true;

        return usersFilters.every(({ fieldCode, values }) => {
            const userValue = userAdditionalFields[fieldCode];
            const fieldData = companyAdditionalDataToMap[fieldCode];

            if (!values || !fieldData) return true;

            let isMatched: boolean = true;

            switch (fieldData.type) {
                case 'number': {
                    const parsedValue = parseInt(values);
                    if (isNaN(parsedValue) || parsedValue != userValue) {
                        isMatched = false;
                    }
                    break;
                }
                case 'string': {
                    if (typeof values !== 'string' || values !== userValue) {
                        isMatched = false;
                    }
                    break;
                }
                case 'select': {
                    const valuesList = [values].flat();

                    if (!valuesList.includes(userValue)) {
                        isMatched = false;
                    }
                    break;
                }
            }

            return isMatched;
        })
    })
}


export default tagColorToPalette