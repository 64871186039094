import StyledLinkifyer, { StyledLinkifyerLines } from "@/components/StyledLinkifyer"
import { CardDescription } from "@/components/ui/card"
import { splitTextIntoLines } from "@/helpers"
import tagColorToPalette from "@/helpers/tagColorToPalette"
import { cn } from "@/lib/utils"
import { setReply } from "@/redux/slices/crm"
import { Block } from "@mui/icons-material"
import { Reply } from "lucide-react"
import moment from "moment"
import { useDispatch } from "react-redux"
import { ReplyCard } from "../ChatInput/ReplyCard"
import TickStatus, { TickStatusType } from "./TickStatus"

export default function MessageChannel({
    replyMessage,
    deleted,
    message,
    status,
    date,
    interaction,
    showReplyMessage,
    channel,
    origin,
    listened,
    mediaType,
    className
}: {
    replyMessage?: any,
    message: string | undefined | null,
    status: TickStatusType,
    date: string | Date,
    deleted?: boolean,
    interaction?: any,
    showReplyMessage?: boolean | undefined | null,
    listened?: boolean,
    channel?: {
        color: string,
        name: string
    },
    origin?: string
    mediaType?: string,
    className?: string
}) {
    const { color, name } = channel || {}

    return (
        <div className={cn(`justify-center relative flex flex-col flex-grow ${mediaType == 'video' ? 'px-4' : ''}`, className)}>
            {replyMessage && <ReplyCard interaction={replyMessage} isReplied={true} />}
            {
                message && mediaType !== 'audio' && (
                    <CardDescription className={`text-neutral-800 font-medium text-[14px]`}>
                        <StyledLinkifyerLines text={message || ''} />
                    </CardDescription>
                )
            }
            <div className="flex justify-between items-center w-full">
                <div>
                    {deleted && <DeletedMessageAlert />}
                    {
                        false && <ReplyInteractionButton
                            interaction={interaction}
                            className={`${showReplyMessage ? 'opacity-100' : 'opacity-0'} mr-4`}
                        />
                    }
                </div>
                <div className="flex items-center gap-3">
                    <div className="flex items-center gap-1">
                        {channel && (
                            <p
                                className="text-[11px] font-medium"
                                style={{
                                    color: color
                                        ? tagColorToPalette[color].primary
                                        : "GrayText",
                                }}
                            >
                                {name}
                            </p>
                        )}
                    </div>
                    <div className="flex items-center gap-1">
                        <CardDescription>{moment(date).format("LT")}</CardDescription>
                        {status && origin == "agent" && (
                            <TickStatus
                                status={status}
                                mediaType={mediaType}
                                listened={listened}
                            />
                        )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export function ReplyInteractionButton({ interaction, className }: { interaction: any, className?: string }) {
    const dispatch = useDispatch()
    const handleReply = () => dispatch(setReply(interaction))

    return (
        <span
            className={cn('text-xs cursor-pointer text-primary  flex gap-1 items-center hover:text-blue-600', className)}
            onClick={handleReply}
        >
            <Reply size={12} />
            Responder
        </span>
    )
}
export function DeletedMessageAlert() {
    return (
        <CardDescription className="text-[13px] mr-7">
            <Block sx={{ fontSize: 14, mr: 0.5 }} />
            Mensaje eliminado
        </CardDescription>
    )
}