import { BtnNextStep } from "@/components/buttons/BtnNextStep"
import { Alert } from "@/components/ui/alert"
import {
  Form
} from "@/components/ui/form"
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet"
import { Feature, isFeatureEnabled } from "@/helpers/featureFlagHelpers"
import { isValidOrigin } from "@/helpers/isValidOrigin"
import useLoadingAndError from "@/hooks/useLoadingAndError"
import { zodResolver } from "@hookform/resolvers/zod"
import phoneValidator from "libphonenumber-js"
import _ from "lodash"
import { AlertCircle, XIcon } from "lucide-react"
import mixpanel from "mixpanel-browser"
import { useRouter } from "next/router"
import React, { useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { useSelector } from "react-redux"
import { z } from "zod"
import { getCompany, isAuth } from "../../../actions/auth"
import { create, update } from "../../../actions/crm/contact"
import { generateDefaultContactFields } from "../../../constants/crm/contactForm"
import { Contact } from "../../../interfaces/crm/contact"
import { FieldByReference } from "./FieldByReference"
import validatePhoneNumber from '@/lib/validatePhoneNumber';

interface ICreateContact {
  id?: string
  open: boolean | any
  setOpen: (newValue: boolean) => void
  onSave: (contact: Contact) => void
}

const errorMessages = {
  required: "Este campo es obligatorio",
  minLength: "Debe tener al menos 2 caracteres",
  maxLength: "No debe tener más de 32 caracteres",
  validEmail: "El correo electrónico debe ser válido",
  validOrigin: "El origen debe ser válido",
  maxLengthComment: "No puede tener más de 255 caracteres",
}

const createContactFormType = z.object({
  firstName: z
    .string({ required_error: errorMessages.required })
    .min(2, { message: errorMessages.minLength })
    .max(100, { message: errorMessages.maxLength }),
  lastName: z.string().max(100, { message: errorMessages.maxLength }),
  phones: z.array(z.string()).optional(),
  phonesMetadata: z.array(z.object({
    extensionNumber: z.string()
  })),
  emails: z.array(z.any().optional()).optional(),
  govId: z.string().optional(),
  statusTag: z.any().optional(),
  additionalData: z.any().optional(),
  contactLocation: z.any().optional(),
  detail: z.string().optional(),
  affiliatedCompanyGroup: z.any().optional(),
  origin: z.string(),
})

export type CreateContactFormType = z.infer<typeof createContactFormType>

const defaultValues: CreateContactFormType = {
  firstName: "",
  lastName: "",
  phones: [""],
  emails: [],
  origin: undefined,
  detail: "",
  govId: "",
  additionalData: {},
  statusTag: undefined,
  phonesMetadata: [{ extensionNumber: '' }]
} as any

const CreateContact = ({ onSave, open, setOpen }: ICreateContact) => {
  const [isDisabledBtn, setDisabledBtn] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | boolean>(false);
  const [defaultPhoneMetadata, setDefaultPhoneMetadata] = useState<CreateContactFormType['phonesMetadata']>([])

  const contactForm = useSelector((state: any) => state.crm.contactForm)
  const router = useRouter()

  const disabledCreateContactButton = useSelector((state: any) => state.crm.disabledCreateContactButton);
  const defaultContactForm = useMemo(() => generateDefaultContactFields(), [getCompany(), isAuth()]);
  const enableInternalPhoneNumbers = isFeatureEnabled(Feature.ENABLE_INTERNAL_PHONE_NUMBERS);

  const requiredFields = useMemo(() => defaultContactForm.fields.filter(field => field.isRequired),
    [defaultContactForm])

  let defaultFields = _.get(defaultContactForm, 'fields', []);
  const defaultFieldsToMap = useMemo(() => _.keyBy(defaultFields, 'reference'), [defaultFields])
  const channels = (open?.channels || []);

  const contactFormUse = useMemo(() => {
    if (!contactForm || contactForm._id == 'default') return defaultContactForm;
    let fields = [..._.get(contactForm, 'fields', [])];
    let fieldsToMap = _.keyBy(fields, 'reference');

    requiredFields.forEach((field) => {
      if (!fieldsToMap[field.reference]) {
        const index = field.index;
        fields.splice(index, 0, field);
      }
    })

    fields = fields.filter((field) => !!defaultFieldsToMap[field.reference]);
    fields = fields.map((field) => ({ ...defaultFieldsToMap[field.reference], ...field }));

    return {
      ...contactForm,
      fields
    }
  }, [contactForm, _.get(contactForm, 'fields', []).length, contactForm?._id, defaultContactForm])

  const { isLoading, setIsLoading } = useLoadingAndError()

  const phoneCode = useMemo(() => _.get(getCompany(), "country", "uy"), [open])
  const isModifyContact = useMemo(() => typeof open !== "boolean", [open])
  const additionalDataFields = useMemo(() => _.get(getCompany(), "additionalDataFields", []), [])
  const additionalDataFieldsToMap = useMemo(() =>
    additionalDataFields.reduce((p, c) => ({ ...p, [String(c.code)]: c }), {}), [additionalDataFields])

  const contextForm = useForm<CreateContactFormType>({
    resolver: zodResolver(createContactFormType),
    defaultValues,
  })

  const formState = contextForm.formState
  const { errors, isValid } = formState;
  const withErrors = Object.keys(errors).length > 0

  const phones = contextForm.watch("phones") || []
  const additionalData = contextForm.watch('additionalData')

  useEffect(() => {
    if (!!open) {
      !!errorMessage && setErrorMessage(false)

      if (isModifyContact) {
        const { emails = [], phones = [], additionalData = {} } = open || {}

        const getArrayValue = (array: string[]) => {
          if (array.length == 0) return ['']
          return array
        };

        const phonesMetadataToMap = _.keyBy(open?.phonesMetadata || [], 'phone');
        const usePhones = getArrayValue(phones);
        let phonesMetadata: CreateContactFormType['phonesMetadata'] = [];

        usePhones.forEach((phone: string) => {
          let metadata = phonesMetadataToMap[phone.replace('+', '')];
          if (!metadata) {
            metadata = { extensionNumber: '' }
          }

          phonesMetadata.push(metadata);
        });

        setDefaultPhoneMetadata(phonesMetadata);

        contextForm.reset({
          ...open,
          emails,
          phonesMetadata,
          phones: usePhones,
          additionalData: additionalData
        })
      } else {
        return contextForm.reset(defaultValues)
      }
    }
    if (typeof open === 'string') {
      contextForm.setValue('phones', [open])
    }
  }, [open]);

  const handleSubmit = (values: CreateContactFormType) => {
    if (withErrors) return;
    setIsLoading(true)
    const contact = { ...values }
    let sendContact: any = Object.assign({}, contact)
    let foundError = false

    const generateErrorForm = ({ nameField, message }) => {
      contextForm.setError(nameField, { message })
      foundError = true
    }

    if (!isModifyContact && !isValidOrigin(contact.origin)) {
      generateErrorForm({
        message: "El origen debe ser válido",
        nameField: "origin",
      })
    }

    if (
      phones[0].length == 0 &&
      ((!_.get(getCompany(), "crmConfig.disableObligatoryPhone", false) && channels.length == 0) ||
        phones.length > 1)
    ) {
      generateErrorForm({
        nameField: "phones.0",
        message: "Se requiere un número de teléfono para el contacto",
      })
    }

    phones.map((phone, indexPhone) => {
      if (phone.length == 0 && indexPhone > 0) {
        generateErrorForm({
          nameField: `phones.${indexPhone}`,
          message: "Es obligatorio completar el teléfono adicional",
        })
      }

      if (phone.length > 0) {
        const validatedPhoneNumber = validatePhoneNumber(phone, phoneCode)

        if (validatedPhoneNumber && validatedPhoneNumber.valid) {
          sendContact.phones[indexPhone] = validatedPhoneNumber.number;
        } else {
          let region = new Intl.DisplayNames(["es"], { type: "region" })

          generateErrorForm({
            nameField: `phones.${indexPhone}`,
            message: `El numero de teléfono tiene que ser un numero valido de ${region.of(
              phoneCode.toUpperCase()
            )} o de otro país con el codigo correspondiente`,
          })
        }
      }
    });

    sendContact.emails = sendContact.emails.filter(Boolean);

    sendContact.emails.forEach((email, i) => {
      const { success } = z.string().email().safeParse(email);
      if (!success) {
        contextForm.setError(`emails.${i}`, { message: 'El email tiene que ser válido' });
        foundError = true
      }
    });

    sendContact.phonesMetadata = sendContact.phones.map((phone: string, inx: number) => {
      const metadata = sendContact.phonesMetadata[inx] || { extensionNumber: '' };
      return { ...metadata, phone }
    });

    if (foundError) return setIsLoading(false)

    sendContact.affiliatedCompanyGroup = (sendContact?.affiliatedCompanyGroup || []).filter((group) => !!group?.companyGroup)

    if (
      getCompany() &&
      additionalDataFields &&
      additionalDataFields.length > 0
    ) {
      let fields = additionalDataFields
      let errors_additionals: string[] = []

      fields.map((field) => {
        if (field.active && field.required && additionalData && !additionalData[field.code]) {
          return errors_additionals.push(
            "El campo " + field.name + " tiene que ser completado"
          )
        }
        if (
          field.type === "number" &&
          additionalData &&
          additionalData[field.code] &&
          isNaN(parseInt(additionalData[field.code] + ""))
        ) {
          return errors_additionals.push(
            "El campo " + field.name + " tiene que ser rellenado con un numero"
          )
        }
      })

      if (errors_additionals.length > 0) {
        setIsLoading(false)
        return setErrorMessage(errors_additionals[0])
      }
    }

    if (!isModifyContact) {
      create(sendContact).then((response) => {
        mixpanel.track("Conctact created", {
          contact: response,
          contactSend: sendContact,
        })

        setIsLoading(false)

        const query = {
          ...router.query,
          id: response._id
        }

        router.push({ query, pathname: '/contact' })
        if (!response) return setErrorMessage("Error en conexion con el servidor")
        if (response.error) return setErrorMessage(response.error)
        onSave(response)
        contextForm.reset(defaultValues)
      })
    } else {
      const contactID = open?._id as string
      update(contactID, sendContact).then((response) => {
        mixpanel.track("Conctact updated", {
          contactId: contactID,
          contactSend: sendContact,
        })
        setIsLoading(false)
        if (!response) return setErrorMessage("Error en conexion con el servidor")
        if (response.error) return setErrorMessage(response.error)
        onSave(response)
        contextForm.reset(defaultValues)
      })
    }
  }

  return (
    <React.Fragment>
      <Sheet open={!!open} onOpenChange={setOpen}>
        <SheetContent className="flex flex-col gap-0 h-[100vh] px-0 pb-2  pt-3">
          <SheetHeader className="pt-0 pb-0 px-5">
            <SheetTitle className="text-xl">
              {isModifyContact ? "Editar contacto" : "Crear contacto"}
            </SheetTitle>
          </SheetHeader>
          <div className="flex flex-col pb-2 mt-2 flex-grow px-5 pr-4 overflow-y-scroll gap-4">
            <Form {...contextForm}>
              <form
                onSubmit={contextForm.handleSubmit(handleSubmit)}
                className="space-y-8"
                id="form-create-user"
              >
                <div className="flex flex-col gap-4 mt-2" key={contactFormUse._id}>
                  {
                    contactFormUse.fields.map((field) => (
                      <FieldByReference
                        enableInternalPhoneNumbers={enableInternalPhoneNumbers}
                        onSetError={(error: string) => setErrorMessage(error)}
                        additionalDataFieldsToMap={additionalDataFieldsToMap}
                        defaultPhoneMetadata={defaultPhoneMetadata}
                        onCLoseSheet={(value) => setOpen(value)}
                        isModifyContact={isModifyContact}
                        contact={open as Contact}
                        contextForm={contextForm}
                        key={field.reference}
                        channels={channels}
                        field={field}
                      />
                    ))
                  }
                </div>
              </form>
            </Form>
          </div>
          <SheetFooter className="sticky bottom-0 w-full">
            <div className="flex flex-col w-full">
              <div className="flex flex-col gap-2 w-full px-5 pr-7">
                {errorMessage && (
                  <Alert
                    variant={"destructive"}
                    className="flex flex-row items-center justify-center w-full bg-red-50"
                  >
                    <AlertCircle className="h-5 w-5 " />
                    <div className="ml-1 text-sm">{errorMessage}</div>
                    <XIcon className="w-5 h-5" />
                  </Alert>
                )}
              </div>
              <div className="w-full  px-5 pr-7  mt-1">
                <BtnNextStep
                  hiddenArrow
                  disabled={withErrors || isDisabledBtn || disabledCreateContactButton || !isValid}
                  type="submit"
                  variant={'default'}
                  className="w-full"
                  form="form-create-user"
                  loading={isLoading}
                >
                  {
                    isModifyContact
                      ? "Guardar"
                      : "Agregar"
                  }
                </BtnNextStep>
              </div>
            </div>
          </SheetFooter>
        </SheetContent>
      </Sheet>
    </React.Fragment>
  )
}

export default CreateContact
